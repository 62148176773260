import React from 'react'

import { t } from '../../utils'

const TextSection = ({ history }) => {
  const scrollToSection = () => {
    const section = document.querySelector('#features-section-2')
    if(section) {
      window.scrollTo({ top: section.getBoundingClientRect().top + window.scrollY, behavior: 'smooth' })
    }
  }

  return (
    <div className="text-section">
      <div className="text-section__head">
        <h3>{t('landing.text_section_title')}</h3>
      </div>
      <div className="text-section__body">
        <p>{t('landing.text_section_desc').split('\n').map((text, i) => <span key={i}>{text}</span>)}</p>
      </div>
      <div className="text-section__foot">
        <button className="button" onClick={() => history.push({ pathname: '/appointment', search: 'location=skip&topic=other&question=other' })}>{t('landing.feature_btn_text')}</button>
        <button className="button button--white" onClick={scrollToSection}>{t('landing.to_video_btn_text')}</button>
      </div>
    </div>
  )
}

export default TextSection