import React, { createContext, useState } from 'react'

export const GlobalContext = createContext()

const GlobalProvider = ({ children }) => {
  const [selectedGlobalExpert, setSelectedGlobalExpert] = useState(null)

  return (
    <GlobalContext.Provider
      value={{
        selectedGlobalExpert,
        setSelectedGlobalExpert,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalProvider