import React, { Fragment, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { languageFromLocale, t, currentLanguage } from '../utils'

const CookiesSection = (props) => {
  const { partner, shouldAcceptCookies } = props
  const [isShow, setIsShow] = useState(false)
  const [showPreferences, setShowPreferences] = useState(false)

  const Locale = () => {
    if (partner) {
      for (let i in partner.locales) {
        if (currentLanguage() === languageFromLocale(partner.locales[i]).code) {
          return partner.locales[i]
        }
      }
    }
    return 'fr_BE'
  }

  const linkCookie = languageFromLocale(Locale()).code === 'nl' ? `assets/nl/Cookies-beleid.pdf` : `assets/fr/Politique-de-cookies.pdf`

  return (
    <div className="cookies-section-wrapper">
      <div className="container">
        <div className="cookies-section">
          <img src="images/cookie.svg" alt="" className="cookies-section__cookie" />
          <div className="cookies-section__top">
            <p>{t('cookies.intro')}. {t('cookies.discription')}</p>
          </div>
          <div className="cookies-section__actions">
            <a href="#" className="green" onClick={(e) => { e.preventDefault(); shouldAcceptCookies(); }}>{t('cookies.toggle_1')}</a>
            <a href="#" className="" onClick={(e) => { e.preventDefault(); shouldAcceptCookies(); }}>{t('cookies.toggle_2')}</a>
            <a href="#" className="" onClick={(e) => { e.preventDefault(); setShowPreferences(true); }}>{t('cookies.toggle_3')}</a>
          </div>
          <div className="cookies-section__bottom">
            <p>
              {t('cookies.detail_text')}&nbsp;
              <a href={linkCookie} className="link" rel="noopener noreferrer" target="_blank">{t('cookies.detail_text_1')}</a>
            </p>
          </div>
          {showPreferences && <Dialog
              scroll="body"
              maxWidth="lg"
              onClose={() => setShowPreferences(false)}
              open={showPreferences}>
                <Fragment>
                  <div className="configure-body configure-body--cookies-section">
                    <div className="configure">
                      <div className="cookie__field">
                        <div className="cookie__text cookie__text--light">
                          {t('cookies.toggle_4')}
                        </div>
                        <label className="switch input-inline-block">
                          <input className="input_toogle" type="checkbox" checked />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="configure__desc">{t('cookies.discription_toggle_4')}</div>
                    </div>
                    <div className="configure">
                      <div className="cookie__field">
                        <div className="cookie__text cookie__text--light">
                          {t('cookies.toggle_5')}
                        </div>
                        <label className="switch input-inline-block">
                          <input className="input_toogle" type="checkbox" />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="configure__desc">{t('cookies.discription_toggle_5')}</div>
                    </div>
                    <div className="configure">
                      <div className="cookie__field">
                        <div className="cookie__text cookie__text--light">
                          {t('cookies.toggle_6')}
                        </div>
                        <label className="switch input-inline-block">
                          <input className="input_toogle" type="checkbox" />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="configure__desc">{t('cookies.discription_toggle_6')}</div>
                    </div>
                    <a href="#" className="green" onClick={(e) => { e.preventDefault(); shouldAcceptCookies(); }}>{t('cookies.save')}</a>
                  </div>
                </Fragment>
            </Dialog>}
        </div>
      </div>
    </div>
  )
}

export default CookiesSection