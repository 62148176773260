import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'

const ExpertCardSkeleton = () => {
  return (
    <div className="expert-card-skeleton">
      <div className="expert-card-skeleton__head"></div>
      <LinearProgress className="expert-card-skeleton__line full" />
      <LinearProgress className="expert-card-skeleton__line" />
      <LinearProgress className="expert-card-skeleton__line" />
      <LinearProgress className="expert-card-skeleton__line small" />
      <LinearProgress className="expert-card-skeleton__line" />
    </div>
  )
} 

export default ExpertCardSkeleton