import React, { useState, useEffect } from 'react'
import Popover from '@material-ui/core/Popover';
import { animateScroll as scroll } from 'react-scroll'

import { currentUser } from '../services/auth'

import { t, languageFromLocale, currentLanguage } from '../utils'

import { Footer, Navbar } from '../components'

import config from '../config.json'

const NavigationPage = (props) => {

  const { showAuth, history, startLoading, stopLoading, refreshInteractions, userData, isLoading, setLocale, partner } = props

  const [email, setEmail] = useState('')
  const [currentPath, setCurrentPath] = useState('/')
  const [accountMenuAnchor, setAccountMenuAnchor] = useState(null)

  useEffect(() => {
    history.listen(location => {
      const { pathname } = location
      setCurrentPath(pathname)
    })
  }, [])

  const navigate = (path) => () => {
    history.push(path)
    closeAccountMenu()
  }

  const navigateAppointment = (path) => () => {
    history.push(path)
    setTimeout(() => {
      scroll.scrollTo(250)
    }, 1);
    closeAccountMenu()
  }

  const openAccountMenu = (e) => {
    setAccountMenuAnchor(e.target)
  }

  const closeAccountMenu = () => {
    setAccountMenuAnchor(null)
  }

  const openAuth = (content) => () => {
    const { showAuth } = props
    showAuth(content)
  }

  useEffect(() => {
    const { pathname } = history.location
    setCurrentPath(pathname)
    // ReactGA.pageview(props.history.location.pathname)
  }, [history.location.pathname])

  useEffect(() => {
    if(props.signedIn) {
      setEmail(currentUser()?.email)
    } else {
      setEmail('')
    }
  }, [props.signedIn])

  const userTitle = () => {
    if(userData && userData.name && userData.name.first) {
      return userData.name.first
    } else if(email) {
      return email
    } else {
      return ''
    }
  }

  return (
    <>
      <Navbar
        history={history}
        showAuth={showAuth}
        email={email}
        startLoading={startLoading}
        stopLoading={stopLoading}
        refreshInteractions={refreshInteractions}
        userData={userData}
        isLoading={isLoading}
        setLocale={setLocale}
        partner={partner} />
      <div className="wrapper">
        <div data-collapse="none" data-animation="default" data-duration="400" role="banner" className="nav--green w-nav">
          <div className="bg-green">
            <div className="container container--nav-green">
              <nav role="navigation" className="nav__menu nav__menu--green w-nav-menu nav__menu--2">
                <div className="nav-links-left">
                  <a onClick={navigate('/')} data-w-id="160bf4b8-b166-11d4-110a-3fd57541a0f9" href="#" aria-current="page" className="nav__link nav__link--white w-inline-block w--current pf0">
                    <div className="gray-bg"></div>
                    <p className="nav__title">{ t('navbar.mle') }<br/></p>
                    <div className="white-line white-line--horizontal"></div>
                  </a>
                  <div className="nav-links-left__divider"></div>
                  {/* <div className="nav-links-wrap nav-links-wrap--green"> */}
                  <a href="#" onClick={navigateAppointment('/appointment?location=appointment')} aria-current="page" className="nav__link nav__link--white w-inline-block w--current">
                    <div className="gray-bg"></div>
                    <p className="nav__title">{ t('navbar.appointment') }<br/></p>
                    <div className="white-line white-line--horizontal"></div>
                  </a>
                  <a onClick={navigate('/help')} href="#" aria-current="page" className="nav__link nav__link--white w-inline-block w--current">
                    <div className="gray-bg"></div>
                    <p className="nav__title">{ t('navbar.help') }<br/></p>
                    <div className="white-line white-line--horizontal"></div>
                  </a>
                  {/* </div> */}
                </div>
                { !email ? (
                  <div className="nav-links-wrap nav-links-wrap--green2">
                    <a onClick={openAuth('register')} href="#" aria-current="page" className="nav__link nav__link--white nav__link--mr nav__link--line w-inline-block w--current">
                      <div className="gray-bg"></div>
                      <p className="nav__title">{ t('auth.sign_up') }<br/></p>
                      <div className="white-line"></div>
                      <div className="white-line white-line--horizontal"></div>
                    </a>
                    <a onClick={openAuth('login')} href="#" className="nav__link nav__link--contact mr0 w-inline-block">
                      <p className="nav__title">{ t('auth.sign_in') }<br/></p>
                      <div className="white-line white-line--horizontal"></div>
                    </a>
                  </div>
                ) : (
                  <div className="dropdown w-dropdown-navbar">
                    <a onClick={openAccountMenu} href="#" className="nav__link nav__link--contact mr0 w-inline-block">
                      <p className="nav__title">{ userTitle() }<br/></p>
                      <div className="white-line white-line--horizontal"></div>
                    </a>
                    <Popover
                      id="account-menu"
                      open={Boolean(accountMenuAnchor)}
                      anchorEl={accountMenuAnchor}
                      onClose={closeAccountMenu}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <div className="dropdown__list w-dropdown-list w--open account">
                        <a onClick={navigateAppointment('/appointment')} href="#" className="dropdown__link w-dropdown-link">{ t('navbar.appointment') }</a>
                        <a onClick={navigate('/appointments')} href="#" className="dropdown__link w-dropdown-link">{ t('account.appointments')}</a>
                        <a onClick={navigate('/profile')} href="#" className="dropdown__link w-dropdown-link">{ t('account.profile') }</a>
                      </div>
                    </Popover>
                  </div>
                ) }
              </nav>
              <div className="menu-button w-nav-button">
                <div className="w-icon-nav-menu"></div><img src="images/close-btn.svg" alt="" className="close-btn"/></div>
            </div>
          </div>
        </div>
        <div className="sec">
          { props.children }
        </div>
      </div>
      <Footer history={history} partner={partner} refreshInteractions={refreshInteractions} />
    </>
  )
}

export default NavigationPage