import React, { useState, useEffect } from 'react'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { t } from '../../utils'

SwiperCore.use([Navigation])

const ExpertsTwo = ({ selectedLocale, history }) => {
  const [swiper, setSwiper] = useState(null)
  const [activeSlide, setActiveSlide] = useState(0)
  const [experts, setExperts] = useState([
    { 
      name: t('landing.expert_1_name'), 
      text: t('landing.expert_1_text_short'), 
      categories: ['commercial_law', 'company_law', 'gdpr', 'intelectual_property', 'contracts'] 
    },
    { 
      name: t('landing.expert_2_name'), 
      text: t('landing.expert_2_text_short'),
      categories: ['intelectual_property', 'data_protection', 'commercial_law']
    },
    { 
      name: t('landing.expert_3_name'), 
      text: t('landing.expert_3_text'),
      categories: ['ip_and_ict', 'data_protection', 'commercial_and_unfair_competition', 'business_law']
    },
    { 
      name: t('landing.expert_4_name'), 
      text: t('landing.expert_4_text_short'),
      categories: ['gdpr', 'data_protection']
    },
    { 
      name: t('landing.expert_5_name'), 
      text: t('landing.expert_5_text_short'),
      categories: ['information_technology_law', 'new_technologies', 'gdpr', 'data_protection']
    },
    { 
      name: t('landing.expert_6_name'), 
      text: t('landing.expert_6_text_short'),
      categories: ['eu_law', 'international_law', 'entrepreneurship']
    },
    { 
      name: t('landing.expert_7_name'), 
      text: t('landing.expert_7_text_short'),
      categories: ['company_law']
    },
    { 
      name: t('landing.expert_8_name'), 
      text: t('landing.expert_8_text_short'),
      categories: ['company_law']
    },
  ])

  const updateExperts = () => {
    setExperts([
      { 
        name: t('landing.expert_1_name'), 
        text: t('landing.expert_1_text_short'), 
        categories: ['commercial_law', 'company_law', 'gdpr', 'intelectual_property', 'contracts'] 
      },
      { 
        name: t('landing.expert_2_name'), 
        text: t('landing.expert_2_text_short'),
        categories: ['intelectual_property', 'data_protection', 'commercial_law']
      },
      { 
        name: t('landing.expert_3_name'), 
        text: t('landing.expert_3_text'),
        categories: ['ip_and_ict', 'data_protection', 'commercial_and_unfair_competition', 'business_law']
      },
      { 
        name: t('landing.expert_4_name'), 
        text: t('landing.expert_4_text_short'),
        categories: ['gdpr', 'data_protection']
      },
      { 
        name: t('landing.expert_5_name'), 
        text: t('landing.expert_5_text_short'),
        categories: ['information_technology_law', 'new_technologies', 'gdpr', 'data_protection']
      },
      { 
        name: t('landing.expert_6_name'), 
        text: t('landing.expert_6_text_short'),
        categories: ['eu_law', 'international_law', 'entrepreneurship']
      },
      { 
        name: t('landing.expert_7_name'), 
        text: t('landing.expert_7_text_short'),
        categories: ['company_law']
      },
      { 
        name: t('landing.expert_8_name'), 
        text: t('landing.expert_8_text_short'),
        categories: ['company_law']
      },
    ])
  }

  useEffect(() => {
    if(swiper) {
      updateExperts()
    }
  }, [selectedLocale])

  const scrollToSection = () => {
    const section = document.querySelector('#features-section-3')
    if(section) {
      window.scrollTo({ top: section.getBoundingClientRect().top + window.scrollY - 100, behavior: 'smooth' })
    }
  }

  return (
    <div className="experts experts--2">
      <div className="experts__overlay"></div>
      <div className="experts__body">
        <h2 className="heading-1">{t('landing.experts_title')}</h2>
        <p className="experts__body_subtitle">{t('landing.experts_2_subtitle')}</p>
        <p className="our-experts">{t('landing.our_experts')}</p>
        <div className="experts__slider-2">
          <Swiper
            onInit={(sw) => setSwiper(sw)}
            slidesPerView={1}
            spaceBetween={30}
            onSlideChange={(sw) => setActiveSlide(sw.activeIndex)}
          >
            {experts.map((expert, i) => (
              <SwiperSlide key={i}>
                <div className="experts__slider-2_slide">
                  <div className="name-wrapper">
                    <p className="name">{expert.name}</p>
                    <div className="shapes">
                      <div className="shapes__1"></div>
                      <div className="shapes__2"></div>
                    </div>
                  </div>
                  <div className="description">
                    <p>{expert.text}</p>
                  </div>
                  <div className="categories">
                    {expert.categories.map((cat, i) => <span key={i}>{t(`categories.${cat}`)}</span>)}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="experts__slider-pagination">
            {experts.map((_, i) => <span key={i} className={activeSlide === i ? "bullet bullet-active" : "bullet"} onClick={() => swiper.slideTo(i)}></span>)}
          </div>
        </div>
        <button className="button" onClick={() => history.push({ pathname: '/appointment', search: 'location=skip&topic=other&question=other' })}>
          <span className="desktop">{t('home.to_appointment')} !</span>
          <span className="mobile">{t('navbar.appointment')}</span>
        </button>
        <div style={{marginTop: '20px'}}>
          <button className="button button--white" onClick={scrollToSection}>{t('landing.to_video_btn_text')}</button>
        </div>
      </div>
    </div>
  )
}

export default ExpertsTwo