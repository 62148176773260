import React from 'react'
import Lottie from 'react-lottie'

import * as titleAnimation from '../../animations/title.json'
import { t } from '../../utils'

const titleAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: titleAnimation.default
}

const Intro = ({ history }) => {
  const scrollToSection = () => {
    const section = document.querySelector('#features-section-1')
    if(section) {
      window.scrollTo({ top: section.getBoundingClientRect().top + window.scrollY, behavior: 'smooth' })
    }
  }

  return (
    <div className="intro">
      <div className="intro__left">
        <h1>{t('landing.intro_title').split('\n').map((text, i) => <span key={i}>{text}</span>)}</h1>
        <div className="button-wrapper">
          <button className="button" onClick={() => history.push({ pathname: '/appointment', search: 'location=skip&topic=other&question=other' })}>
            <span className="desktop">{t('home.to_appointment')} !</span>
            <span className="mobile">{t('navbar.appointment')}</span>  
          </button>
        </div>
        <button className="button button--white" onClick={scrollToSection}>{t('landing.to_video_btn_text')}</button>
      </div>
      <div className="intro__right">
        <div className="lottie-wrapper">
          <div className="lottie-container">
            <Lottie options={titleAnimationOptions} isStopped={false} isPaused={false} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Intro