import React, { useState, useEffect, useContext } from 'react'
import { APStepTopic, APStepQuestion, APStepDetail, APStepSolution, APStepCheckout, APStepProfile, Breadcrumb } from '../components'
import { paramsFromSearch, updateUrlParams, t, currentLanguage, paramsArrayFromSearch, searchStringFromParamsArray } from '../utils'
import { uploadFile } from '../services/database'
import { createFreeAppointmentRequest } from '../services/functions'
import config from '../config.json'
import { currentUserUid } from '../services/auth'
import { animateScroll as scroll } from 'react-scroll'
import moment from 'moment'
import { GlobalContext } from '../context'

const AppointmentPlanning = (props) => {

  const { topics, questions, history, startLoading, stopLoading, showSnackbar, showAuth, refreshInteractions, fetchAppointments, fetchUserData, partner, userData, userDataFetched } = props
  const { setSelectedGlobalExpert } = useContext(GlobalContext)
  const [selectedTopic, setSelectedTopic] = useState(null)
  const [selectedQuestion, setSelectedQuestion] = useState(null)
  const [profile, setProfile] = useState(false)
  const [solution, setSolution] = useState(false)
  const [selectedSolution, setSelectedSolution] = useState(null)
  const [checkout, setCheckout] = useState(false)
  const [comment, setComment] = useState('')
  const [attachments, setAttachments] = useState([])
  const [date, setDate] = useState(null)
  const [voucherCode, setVoucherCode] = useState('')
  const [reducedPrice, setReducedPrice] = useState(-1)
  const [dataReady, setDataReady] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [selectedExpert, setSelectedExpert] = useState(null)

  useEffect(() => {
    let params = paramsFromSearch(history.location.search)
    if(params.topic) {
      setSelectedTopic(params.topic)
    } else {
      setSelectedTopic(null)
    }
    if(params.question) {
      setSelectedQuestion(params.question)
    } else {
      setSelectedQuestion(null)
    }
    if(params.location) {
      setSelectedLocation(params.location)
    } else {
      setSelectedLocation(null)
    }
    if(params.expert && !selectedExpert) {
      setSelectedExpert(params.expert)
    }
    setSolution(Boolean(params.solution))
    setCheckout(Boolean(params.checkout))
    setProfile(Boolean(params.profile))
    setDataReady(true)
  }, [history.location, props.topics, props.questions])

  const selectTopic = (t) => {
    setTimeout(() => {
      scroll.scrollTo(250)
    }, 1);
    updateUrlParams(history, { topic: t.id })
  }

  const selectQuestion = (q) => {
    setTimeout(() => {
      scroll.scrollTo(250)
    }, 1);
    updateUrlParams(history, { question: q.id })
  }

  const currentStep = () => {
    if(checkout && !!selectedTopic && !!selectedQuestion && selectedSolution !== null) {
      return 'checkout'
    } else if(solution && !!selectedTopic && !!selectedQuestion && !!date) {
      return 'solution'
    } else if(profile && !!selectedTopic && !!selectedQuestion && !!date) {
      return 'profile'
    } else if(!!selectedTopic && !!selectedQuestion) {
      return 'details'
    } else if(!!selectedTopic) {
      return 'question'
    } else {
      return 'topic'
    }
  }

  const onDetailSubmit = (c, atts, d) => {
    setComment(c)
    setAttachments([...atts])
    setDate(d)
    updateUrlParams(history, { profile: true })
  }

  const onSolutionSelected = async (solutionIndex, solution) => {
    setSelectedSolution(solutionIndex)
    if(+solution.price === 0 || reducedPrice === 0) {
      startLoading('checkout');
      const draftData = await createAppointmentDraft(solutionIndex);
      let requestResult = await createFreeAppointmentRequest(draftData, voucherCode)

      if(requestResult.error) {
        console.log(requestResult.error)
        showSnackbar({ text: requestResult.error.message, color: 'error' })
        stopLoading('checkout')
        return
      }
      onCheckoutSuccess()
      stopLoading('checkout');
      
    } else {
      updateUrlParams(history, { checkout: true })
    }
  }

  const onCheckoutSubmitted = async () => {
    return await createAppointmentDraft(selectedSolution)
  }

  const createAppointmentDraft = async (selectedSolutionIndex) => {
    startLoading('creatingDraft')
    const now = moment().valueOf()
    let data = {
      topic: selectedTopic,
      question: selectedQuestion,
      comment,
      date,
      solutionId: partner.solutions[selectedSolutionIndex].id,
      language: currentLanguage(),
      meta: {
        created: now,
        updated: now
      }
    }
    if(selectedExpert) {
      data.expert = emailForExpert(selectedExpert)      
    }
    if(!!attachments && attachments.length > 0) {
      data.attachments = []
      for(let i in attachments) {
        let att = attachments[i]
        if(att.file) {
          let uploadResult = await uploadFile(att.file, `${config.environment}_${att.name}`, `users/${currentUserUid()}/attachments`)
          let responseJson = await uploadResult.json()
          if(!responseJson.error) {
            data.attachments.push({
              name: att.name,
              url: responseJson.url
            })
          } else {
            console.log('Upload error', responseJson)
          }
        } else if(att.url) {
          data.attachments.push(att)
        }
      }
    }
    // let result = await updateAppointment(request, attachments)
    stopLoading('creatingDraft')
    return data
  }

  const emailForExpert = (expertName) => {
    for(let e of partner.experts) {
      if(e.name === expertName) {
        return e.email
      }
    }
    return ''
  }

  const onExpertChange = (expertName, expert) => {
    if(expertName === selectedExpert) {
      return
    }
    let params = paramsArrayFromSearch(history.location.search)
    for(let i in params) {
      if(params[i].key === 'expert') {
        params[i].value = expertName
      }
    }
    // history.location.search = searchStringFromParamsArray(params)
    history.replace({ pathname: history.location.pathname, search: searchStringFromParamsArray(params) }); 
    setSelectedExpert(expertName)  
    setSelectedGlobalExpert(expert)    
  }

  const onCheckoutSuccess = async () => {
    
    fetchAppointments()
    
    history.replace('/appointment-confirmed', {
      appointment: {
        topic: selectedTopic,
        question: selectedQuestion,
        comment,
        date
      },
    })
  }

  const onNavigate = (path) => {
    history.push(path)
  }

  const onSaveProfile = () => {
    updateUrlParams(history, { solution: true })
  }

  const onVoucherApplied = (code, newPrice) => {
    setVoucherCode(code)
    setReducedPrice(newPrice)
  }

  const renderStep = () => {
    switch(currentStep()) {
      case 'topic': 
        return <APStepTopic 
                topics={topics}
                onTopicSelect={selectTopic}
                history={history}
                refreshInteractions={refreshInteractions} />
      case 'question':
        return <APStepQuestion
                topics={topics}
                questions={questions}
                selectedTopic={selectedTopic}
                onQuestionSelect={selectQuestion}
                refreshInteractions={refreshInteractions} />
      case 'details':
        return <APStepDetail
                topics={topics}
                questions={questions}
                selectedQuestion={selectedQuestion}
                showAuth={showAuth}
                showSnackbar={showSnackbar}
                onSubmit={onDetailSubmit}
                refreshInteractions={refreshInteractions}
                partner={partner} 
                selectedExpert={selectedExpert} 
                onExpertChange={onExpertChange}
                history={history} />
      case 'profile':
        return <APStepProfile
                onSave={onSaveProfile}
                userData={userData}
                showSnackbar={showSnackbar}
                startLoading={startLoading}
                stopLoading={stopLoading}
                fetchUserData={fetchUserData}
                refreshInteractions={refreshInteractions} 
                userDataFetched={userDataFetched} />
      case 'solution':
        return <APStepSolution
                onSolutionSelected={onSolutionSelected}
                partner={partner}
                refreshInteractions={refreshInteractions}
                showSnackbar={showSnackbar}
                startLoading={startLoading}
                stopLoading={stopLoading}
                onVoucherApplied={onVoucherApplied}
                userData={userData} />
      case 'checkout':
        return <APStepCheckout
                showSnackbar={showSnackbar}
                startLoading={startLoading}
                stopLoading={stopLoading}
                showAuth={showAuth}
                userData={userData}
                voucherCode={voucherCode}
                refreshInteractions={refreshInteractions}
                onCheckoutSubmit={onCheckoutSubmitted}
                onCheckoutSuccess={onCheckoutSuccess} />
      default:
        return <div></div>
    }
  }

  const renderBreadcrumbs = () => {
    let path;
    // if(config.partner === 'lv') {
    //   path = [{ label: '', url: '/' }, { label: t('navbar.appointment'), url: '/appointment' }]
    //   if(selectedTopic) {
    //     path.push({ label: topics[selectedTopic]?.title[currentLanguage()], url: `/appointment?topic=${selectedTopic}` })
    //   }
    //   if(selectedQuestion) {
    //     path.push({ label: questions[selectedQuestion]?.text[currentLanguage()], url: `/appointment?topic=${selectedTopic}&question=${selectedQuestion}` })
    //   }
    //   if(solution && !!date) {
    //     path.push({ label: t('breadcrumb.solution'), url: `/appointment?topic=${selectedTopic}&question=${selectedQuestion}&solution=true`})
    //   }
    //   if(checkout && !!date && selectedSolution !== null) {
    //     path.push({ label: t('breadcrumb.payment_method'), url: `/appointment?topic=${selectedTopic}&question=${selectedQuestion}&checkout=true`})
    //   }
    // }else {
      path = [{ label: '', url: '/' }]
      if(selectedLocation) {
        if(selectedLocation === 'appointment') {
          path.push({ label: t('navbar.appointment'), url: `/appointment?location=appointment` })
        }
        if(selectedTopic && selectedLocation !== 'skip') {
          let label = t('breadcrumb.legal_area'), label2 = t('breadcrumb.question')

          if(selectedLocation === 'home') {
            path.push({ label, url: `/` })
          }
          path.push({ label: label2, url: `/appointment?location=${selectedLocation}&topic=${selectedTopic}` })
        }
        if(selectedQuestion) {
          let label = t('breadcrumb.date_and_time')

          path.push({ label, url: `/appointment?location=${selectedLocation}&topic=${selectedTopic}&question=${selectedQuestion}` })
        }
        if(profile && !!date) {
          path.push({ label: t('breadcrumb.contact'), url: `/appointment?location=${selectedLocation}&topic=${selectedTopic}&question=${selectedQuestion}&profile=true`})
        }
        if(solution && !!date) {
          path.push({ label: t('breadcrumb.formules'), url: `/appointment?location=${selectedLocation}&topic=${selectedTopic}&question=${selectedQuestion}&profile=true&solution=true`})
        }
        if(checkout && !!date && selectedSolution !== null) {
          path.push({ label: t('breadcrumb.payment'), url: `/appointment?location=${selectedLocation}&topic=${selectedTopic}&question=${selectedQuestion}&profile=true&solution=true&checkout=true`})
        }
      // }
    }

    return (
      <Breadcrumb path={path} navigate={onNavigate} />
    )
  }

  return (
    <div className="container container--hero container--with-breadcrumbs">
      { renderBreadcrumbs() }
      { dataReady && renderStep() }
    </div>
  )
}

export default AppointmentPlanning