import React from 'react'

import IntroTwo from '../components/landing/IntroTwo'
import TextSection from '../components/landing/TextSection'
import Experts from '../components/landing/Experts'
import VideoSection from '../components/landing/VideoSection'
import Features from '../components/landing/Features'
import Packages from '../components/landing/Packages'
import Testimonials from '../components/landing/Testimonials'

const LandingTwo = (props) => {
  return (
    <div className="landing">
      <IntroTwo history={props.history} selectedLocale={props.selectedLocale} />
      <TextSection history={props.history} />
      <Experts selectedLocale={props.selectedLocale} />
      <VideoSection title="v2" />
      <Features hideVideoButton history={props.history} id="features-section-2" showToFaqBtnInsideActions />
      <Packages history={props.history} /> 
      <Testimonials selectedLocale={props.selectedLocale} />
    </div>
  )
}

export default LandingTwo