import React, { useEffect, useState } from 'react'
import { t, languageCodesFromLocales, currentLanguage, languageFromLocale } from '../utils'
import config from '../config.json'
const Footer = (props) => {

  const { refreshInteractions, partner } = props
  const Locale = () => {
    if (partner) {
      for (let i in partner.locales) {
        if (currentLanguage() === languageFromLocale(partner.locales[i]).code) {
          return partner.locales[i]
        }
      }
    }
    return 'fr_BE'
  }

  const links = [
    {
      title: t('footer.cookie_policy'),
      link: languageFromLocale(Locale()).code === 'nl' ? `assets/nl/Cookies-beleid.pdf` : `assets/fr/Politique-de-cookies.pdf`
    },
    {
      title: t('footer.use_website'),
      link: languageFromLocale(Locale()).code === 'nl' ? `assets/nl/Gebruik-van-het-website.pdf` : `assets/fr/Utilisation-du-site-Web.pdf`
    },
    {
      title: t('footer.privacy_policy'),
      link: languageFromLocale(Locale()).code === 'nl' ? `assets/nl/Privacy-beleid.pdf` : `assets/fr/Politique-vie-privée.pdf`
    },
    {
      title: t('footer.terms_of_service'),
      link: languageFromLocale(Locale()).code === 'nl' ? `assets/nl/Service-voorwaarden.pdf` : `assets/fr/Conditions-d'utilisation.pdf`
    }
  ]

  useEffect(() => {
    refreshInteractions()
  }, [])

  const renderFooter = () => {
    switch (config.partner) {
      case 'lv':
        return (
          <div className="sec sec--footer">
            <div className="container container--footer _2">
              <div className="row">
                <div className="col footer__col"><a href="index.html" aria-current="page" className="footer__link w-inline-block w--current"><img src="images/Legal-Village.svg" alt="" className="footer__logo" /></a>
                  {/* <div className="footer__link-wrap">
                    <a data-w-id="b3f7fd10-57a7-00e7-c6f2-6441f9710ae8" href={t('footer.legal_village_url')} className="footer-link footer-link--mr w-inline-block">
                      <div className="link-text">{t('footer.legal_village_button')}</div>
                    </a>
                    <a data-w-id="a6a770d1-f1f4-4686-e287-010cda5f9539" href={t('footer.info_url')} className="footer-link w-inline-block">
                      <div className="link-text">{t('footer.info_button')}</div>
                    </a>
                  </div> */}
                  <div className="footer__link-wrap">
                    {links.map((link, linkIndex) => {
                      return (
                        <a key={`footer_link_${linkIndex}`} className="footer__link w-inline-block w--current" href={link.link} rel="noopener noreferrer" target="_blank">{link.title}</a>
                      )
                    })
                    }
                  </div>
                </div>

                {/* <div className="col"><a href="index.html" aria-current="page" className="footer__link w-inline-block w--current"><img src="images/Legal-Village.svg" alt="" className="footer__logo footer__logo--opacity" /></a>
                  <div className="footer__link-wrap footer__link-wrap--right">
                    <a href="#" className="footer__social-link w-inline-block">
                      <svg className="svg_linkedin" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.977721 0H23.0893C23.6157 0 24.067 0.45 23.9918 0.975V23.025C23.9918 23.55 23.5405 24 23.014 24H0.977721C0.451256 24 0 23.55 0 22.95V0.975C0 0.45 0.451256 0 0.977721 0ZM7.14488 20.475V9H3.53484V20.475H7.14488ZM5.33986 7.425C4.21172 7.425 3.234 6.525 3.234 5.325C3.234 4.2 4.13651 3.225 5.33986 3.225C6.468 3.225 7.44572 4.125 7.44572 5.325C7.44572 6.525 6.468 7.425 5.33986 7.425ZM16.9973 20.475H20.5321V14.175C20.5321 11.1 19.8553 8.7 16.2452 8.7C14.5154 8.7 13.312 9.675 12.8608 10.575H12.7856V9H9.47637V20.55H13.0112V14.85C13.0112 13.35 13.2368 11.925 15.1171 11.925C16.9973 11.925 16.9973 13.575 16.9973 14.925V20.475Z" fill="#B9BBB7" />
                      </svg>
                    </a>
                    <a href="#" className="footer__social-link w-inline-block">
                      <svg className="svg_facebook" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.0893 0H0.977721C0.451256 0 0 0.45 0 0.975V22.95C0 23.55 0.451256 24 0.977721 24H12.7856V15H9.77721V11.25H12.7856V8.25C12.7856 5.175 14.741 3.6 17.5238 3.6C18.8775 3.6 20.0057 3.75 20.3065 3.75V6.975H18.3511C16.8469 6.975 16.4708 7.725 16.4708 8.775V11.25H20.2313L19.4792 15H16.4708L16.546 24H23.014C23.5405 24 23.9918 23.55 23.9918 23.025V0.975C24.067 0.45 23.6157 0 23.0893 0Z" fill="#B9BBB7" />
                      </svg>
                    </a>
                    <a href="#" className="footer__social-link w-inline-block">
                      <svg className="svg_youtube" width="33" height="24" viewBox="0 0 33 24" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M31.35 1.80531C32.3812 2.86726 32.6906 5.20354 32.6906 5.20354C32.6906 5.20354 33 7.9646 33 10.7257V13.2743C33 16.0354 32.6906 18.7965 32.6906 18.7965C32.6906 18.7965 32.3812 21.1327 31.35 22.1947C30.274 23.3951 29.042 23.5517 28.332 23.642C28.2255 23.6555 28.1307 23.6676 28.05 23.6814C23.4094 24 16.5 24 16.5 24C16.5 24 7.94063 23.8938 5.25938 23.6814C5.09474 23.633 4.88182 23.6011 4.63653 23.5644C3.80633 23.4401 2.60526 23.2604 1.65 22.1947C0.61875 21.1327 0.309375 18.7965 0.309375 18.7965C0.309375 18.7965 0 16.0354 0 13.2743V10.7257C0 7.9646 0.309375 5.20354 0.309375 5.20354C0.309375 5.20354 0.61875 2.86726 1.65 1.80531C2.72605 0.604891 3.95804 0.448277 4.66798 0.358027C4.77451 0.344486 4.86927 0.332439 4.95 0.318584C9.59062 0 16.5 0 16.5 0C16.5 0 23.4094 0 28.05 0.318584C28.1307 0.332439 28.2255 0.344486 28.332 0.358027C29.042 0.448277 30.274 0.604891 31.35 1.80531ZM22.44 11.3333L13.2 6.66667V16L22.44 11.3333Z" fill="#B9BBB7" />
                      </svg>
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        )
      case 'axa':
        return (
          <div className="sec sec--footer">
            <div className="container container--footer _2">
              <div className="row">
                <div className="col footer__col"><a href="index.html" aria-current="page" className="footer__link w-inline-block w--current"><div style={{ fontSize: 2.5 + 'rem' }} className="link-text">AXA</div></a>
                  <div className="footer__link-wrap">
                    <a data-w-id="b3f7fd10-57a7-00e7-c6f2-6441f9710ae8" href={t('footer.legal_village_url')} className="footer-link footer-link--mr w-inline-block">
                      <div className="link-text">{t('footer.infomation')}</div>
                    </a>
                    <a data-w-id="a6a770d1-f1f4-4686-e287-010cda5f9539" href={t('footer.info_url')} className="footer-link footer-link--mr w-inline-block">
                      <div className="link-text">{t('footer.advantages')}</div>
                    </a>
                    <a data-w-id="a6a770d1-f1f4-4686-e287-010cda5f9539" href={t('footer.info_url')} className="footer-link footer-link--mr w-inline-block">
                      <div className="link-text">{t('footer.business')}</div>
                    </a>
                  </div>
                  {/* <div className="footer__link-wrap">
                    {links.map((link, linkIndex) => {
                      return (
                        <a key={`footer_link_${linkIndex}`} className="footer__link w-inline-block w--current" href={link.link} rel="noopener noreferrer" target="_blank">{link.title}</a>
                      )
                    })
                    }
                  </div> */}
                </div>

                <div className="col"><a href="index.html" aria-current="page" className="footer__link w-inline-block w--current"><img src="images/Legal-Village.svg" alt="" className="footer__logo footer__logo--opacity" /></a>
                  <div className="footer__link-wrap footer__link-wrap--right">
                    <a href="#" className="footer__social-link w-inline-block">
                      <svg className="svg_linkedin" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.977721 0H23.0893C23.6157 0 24.067 0.45 23.9918 0.975V23.025C23.9918 23.55 23.5405 24 23.014 24H0.977721C0.451256 24 0 23.55 0 22.95V0.975C0 0.45 0.451256 0 0.977721 0ZM7.14488 20.475V9H3.53484V20.475H7.14488ZM5.33986 7.425C4.21172 7.425 3.234 6.525 3.234 5.325C3.234 4.2 4.13651 3.225 5.33986 3.225C6.468 3.225 7.44572 4.125 7.44572 5.325C7.44572 6.525 6.468 7.425 5.33986 7.425ZM16.9973 20.475H20.5321V14.175C20.5321 11.1 19.8553 8.7 16.2452 8.7C14.5154 8.7 13.312 9.675 12.8608 10.575H12.7856V9H9.47637V20.55H13.0112V14.85C13.0112 13.35 13.2368 11.925 15.1171 11.925C16.9973 11.925 16.9973 13.575 16.9973 14.925V20.475Z" fill="#B9BBB7" />
                      </svg>
                    </a>
                    <a href="#" className="footer__social-link w-inline-block">
                      <svg className="svg_facebook" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.0893 0H0.977721C0.451256 0 0 0.45 0 0.975V22.95C0 23.55 0.451256 24 0.977721 24H12.7856V15H9.77721V11.25H12.7856V8.25C12.7856 5.175 14.741 3.6 17.5238 3.6C18.8775 3.6 20.0057 3.75 20.3065 3.75V6.975H18.3511C16.8469 6.975 16.4708 7.725 16.4708 8.775V11.25H20.2313L19.4792 15H16.4708L16.546 24H23.014C23.5405 24 23.9918 23.55 23.9918 23.025V0.975C24.067 0.45 23.6157 0 23.0893 0Z" fill="#B9BBB7" />
                      </svg>
                    </a>
                    <a href="#" className="footer__social-link w-inline-block">
                      <svg className="svg_youtube" width="33" height="24" viewBox="0 0 33 24" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M31.35 1.80531C32.3812 2.86726 32.6906 5.20354 32.6906 5.20354C32.6906 5.20354 33 7.9646 33 10.7257V13.2743C33 16.0354 32.6906 18.7965 32.6906 18.7965C32.6906 18.7965 32.3812 21.1327 31.35 22.1947C30.274 23.3951 29.042 23.5517 28.332 23.642C28.2255 23.6555 28.1307 23.6676 28.05 23.6814C23.4094 24 16.5 24 16.5 24C16.5 24 7.94063 23.8938 5.25938 23.6814C5.09474 23.633 4.88182 23.6011 4.63653 23.5644C3.80633 23.4401 2.60526 23.2604 1.65 22.1947C0.61875 21.1327 0.309375 18.7965 0.309375 18.7965C0.309375 18.7965 0 16.0354 0 13.2743V10.7257C0 7.9646 0.309375 5.20354 0.309375 5.20354C0.309375 5.20354 0.61875 2.86726 1.65 1.80531C2.72605 0.604891 3.95804 0.448277 4.66798 0.358027C4.77451 0.344486 4.86927 0.332439 4.95 0.318584C9.59062 0 16.5 0 16.5 0C16.5 0 23.4094 0 28.05 0.318584C28.1307 0.332439 28.2255 0.344486 28.332 0.358027C29.042 0.448277 30.274 0.604891 31.35 1.80531ZM22.44 11.3333L13.2 6.66667V16L22.44 11.3333Z" fill="#B9BBB7" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
  }

  return (
    <div className="sec sec--footer">
            <div className="container container--footer _2">
              <div className="row">
                <div className="col footer__col"><a href="index.html" aria-current="page" className="footer__link w-inline-block w--current"><img src="images/Legal-Village.svg" alt="" className="footer__logo" /></a>
                  <div className="footer__link-wrap">
                    <a data-w-id="b3f7fd10-57a7-00e7-c6f2-6441f9710ae8" href={t('footer.legal_village_url')} className="footer-link footer-link--mr w-inline-block">
                      <div className="link-text">{t('footer.legal_village_button')}</div>
                    </a>
                  </div>
                  <div className="footer__link-wrap">
                    <a className="footer__link w-inline-block w--current" rel="noopener noreferrer" target="_blank">{t('footer.legal_protection')}</a>
                  </div>
                  <div className="footer__link-wrap footer__link-wrap--2">
                    {links.map((link, linkIndex) => {
                      return (
                        <a key={`footer_link_${linkIndex}`} className="footer__link w-inline-block w--current" href={link.link} rel="noopener noreferrer" target="_blank">{link.title}</a>
                      )
                    })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
  )
}

export default Footer
