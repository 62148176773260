import React, { useEffect, useState } from 'react'
import { KeyboardArrowRight } from '@material-ui/icons'
import { sortedArrayFromObject, currentLanguage, t } from '../utils'
import Lottie from 'react-lottie';
import * as titleAnimation from '../animations/title.json'
import * as calendarAnimation from '../animations/calendar.json'
import * as questionAnimation from '../animations/question.json'
import * as phoneAnimation from '../animations/phone.json'
import config from '../config.json';
import { TopicSkeleton, SolutionBlock, ExpertCard, ExpertCardSkeleton, Loader } from '../components';
import { animateScroll as scroll } from 'react-scroll'

const Home = (props) => {

  const { refreshInteractions, partner } = props

  const [topicQuery, setTopicQuery] = useState('')
  const [topics, setTopics] = useState([])
  const [experts, setExperts] = useState([])
  const [expertsLoaded, setExpertsLoaded] = useState(false)

  useEffect(() => {
    const tArray = sortedArrayFromObject(props.topics)
    let filteredTopics = []
    for(let i in tArray) {
      if(tArray[i].hidden) {
        continue
      }
      filteredTopics.push(tArray[i])
    }
    setTopics([...filteredTopics])
  }, [props.topics])

  useEffect(() => {
    if (!partner) {
      return
    }
    if (partner.experts) {
      setExperts(partner.experts)
      setExpertsLoaded(true)
    }
  }, [partner])

  useEffect(() => {
    if (topics.length > 0) {
      refreshInteractions()
    }
  }, [topics, experts, partner])

  const navigate = (path) => () => {
    setTimeout(() => {
      scroll.scrollTo(250)
    }, 1);
    const { history } = props
    history.push(path)
  }

  const searchFieldOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      submitSearch()
    }
  }

  const submitSearch = () => {
    navigate(`/appointment?topicQuery=${topicQuery}`)()
  }

  const renderSolution = (solution, si) => {
    return (
      <SolutionBlock index={si} features={partner.solution_features} key={`solution_${si}`} solution={solution} />
    )
  }

  const titleAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: titleAnimation.default
  }

  const calendarAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: calendarAnimation.default
  }

  const questionAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: questionAnimation.default
  }

  const phoneAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: phoneAnimation.default
  }

  const renderSkeletonTopics = () => {
    let tpcs = ['', '', '', '', '', '']
    return tpcs.map((topic, ti) => {
      return (
        <TopicSkeleton col={(ti % 3) + 1} key={`topic_skeleton_${ti}`} />
      )
    })
  }

  return (
    <div className="container">
      <div className="container container--hero left-align">
        <div className="row home-hero">
          <div className="col hero-col title">
            <h1 className="hero-title">{t('home.title')}<br /></h1>
            <p className="hero-subtitle left-align">{t('home.subtitle')}<br /></p>
             <div className="button-wrapper">
              <div className="button" onClick={navigate('/appointment?location=skip&topic=other&question=other')}>{t('home.to_appointment')} <KeyboardArrowRight /></div>
            </div>
            {/* {config.partner === 'lv' 
             ? <div className="button-wrapper">
                <div className="button" onClick={navigate('/appointment?location=skip&topic=other&question=other')}>{t('home.to_appointment')} <KeyboardArrowRight /></div>
              </div>
            : <div className="search-wrap">
                <div action="/search" className="search-box w-form">
                  <input type="search" maxLength="256" name="query" onKeyDown={searchFieldOnKeyDown} placeholder={t('home.search_placeholder')} required="" className="hero__search-input w-input" value={topicQuery} onChange={e => setTopicQuery(e.target.value)} />
                  <div className="search__btn-wrap">
                    <input type="submit" value={t('home.search')} onClick={submitSearch} className="btn btn--search w-button" />
                  </div>
                </div>
              </div>
            } */}
          </div>
          <div className="col hero-col-sm">
            <div className="lottie-wrapper">
              <div className="lottie-content">
                <Lottie options={titleAnimationOptions}
                  isStopped={false}
                  isPaused={false} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="services-wrap services-wrap--space-bottom">
        <h3 className="h3 h3--bold">{t('home.experts_title')}<br /></h3>
        <div className="circles-wrap circles-wrap--home">
          <div className="col__cont-wrap col__cont-wrap--relative">
            {!expertsLoaded && (
              <div className="experts-wrapper">
                {Array(4).fill().map((_, idx) => (
                  <ExpertCardSkeleton key={idx} />
                ))}
              </div>
            )}
            {expertsLoaded && <div className="experts-wrapper">
              {experts.map((expert, ei) => {
                return config.partner === 'lv' ? (
                  <ExpertCard key={`home_expert_${ei}`} expert={expert} navigate={navigate} />
                ) : (
                  <div className="expert-info-wrap expert-info-wrap--home" key={`home_expert_${ei}`}>
                    <div style={{ backgroundImage: `url('${expert.image}')` }} className="expert-box circle-box--3 fd">
                      <div className="expert-desc">
                        <div>
                          <h4 className="expert__h4">{expert.name}<br /></h4>
                          <p className="expert__p">{expert.bio[currentLanguage()]}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>}
          </div>
        </div>
      </div>

      <h2 id="home-categories" data-w-id="39185bb0-d550-001e-6872-4c56a56e0c5e" style={{ opacity: 0 }} className="h2">{t('home.category_title')}<br /></h2>
      <p data-w-id="93004192-8b4f-6863-b57b-cb32bb3cfa27" style={{ opacity: 0 }} className="subtitle">{t('home.category_subtitle')}<br /></p>
      <div className="w-layout-grid grid grid--home">
        { !topics || topics.length === 0 ? 
          renderSkeletonTopics() :
          topics.map((topic, ti) => {
            return (
              <div onClick={navigate(`/appointment?location=home&topic=${topic.id}`)} className="img-text-wrap" key={`topic_${ti}`}>
                <div className={`img-box img-box--${parseInt(ti) + 1}`} style={{ backgroundImage: `url('${topic.image}')` }}></div>
                <div className="overlay"></div>
                <div className="text-bg"></div>
                <div className="text-box">
                  <div className="grid__title-box">
                    <h3 className="h3 h3--white"><span>{topic.title[currentLanguage()]}</span></h3>
                    <p className="grid__desc">{topic.description[currentLanguage()]}</p>
                  </div>
                  <div className="btn-wrap btn-wrap--short"><a data-w-id="bbd557fe-3b93-4b41-2157-50f6dd8551ec" href="#" className="btn btn--grid w-button"><span>{t('home.help_question')}</span></a><img src="images/arrow-right__white.svg" alt="" className="img-arrow" /></div>
                </div>
              </div>
            )
          })
        }
      </div>

      <div className="features-wrap">
        <h3 className="h3 h3--bold">{t('home.help_title')}<br /></h3>
        <div className="row">
          <div className="col col--short">
            <div className="col__cont-wrap col__cont-wrap--vertical _1">
              <div className="lottie-wrapper">
                <div className="lottie-content">
                  <Lottie
                    options={questionAnimationOptions}
                    isStopped={false}
                    isPaused={false}
                    width={150}
                    height={150} />
                </div>
              </div>
              <h4 className="h4">{t('home.help_question')}<br /></h4>
              <p className="p col__p">{t('home.help_question_subtitle')}<br /></p>
            </div>
          </div>
          <div className="col col--short">
            <div className="col__cont-wrap col__cont-wrap--vertical _2">
              <div className="lottie-wrapper">
                <div className="lottie-content">
                  <Lottie
                    options={calendarAnimationOptions}
                    isStopped={false}
                    isPaused={false}
                    width={150}
                    height={150} />
                </div>
              </div>
              <h4 className="h4">{t('home.help_session')}<br /></h4>
              <p className="p col__p">{t('home.help_session_subtitle')}<br /></p>
            </div>
          </div>
          <div className="col col--short">
            <div className="col__cont-wrap col__cont-wrap--vertical _3">
              <div className="lottie-wrapper">
                <div className="lottie-content">
                  <Lottie
                    options={phoneAnimationOptions}
                    isStopped={false}
                    isPaused={false}
                    width={150}
                    height={150} />
                </div>
              </div>
              <h4 className="h4">{t('home.help_response')}<br /></h4>
              <p className="p col__p">{t('home.help_response_subtitle')}<br /></p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="formules-wrap formules-wrap--home">
        <h3 className="h3 h3--bold">{t('home.solutions_title')}<br /></h3>
        <div className="row row--short2">
          {partner && partner.solutions 
            ? partner.solutions.map(renderSolution) 
            : (
              <div className="loader-wrapper"><Loader primary normal /></div>
            )}
        </div>
        <div className="button-wrapper button-wrapper--center">
          <div className="button" onClick={navigate('/appointment?location=skip&topic=other&question=other')}>{t('home.to_appointment')} <KeyboardArrowRight /></div>
        </div>
        {/* {config.partner === 'lv' 
          ? 
          <div className="button-wrapper button-wrapper--center">
            <div className="button" onClick={navigate('/appointment?location=skip&topic=other&question=other')}>{t('home.to_appointment')} <KeyboardArrowRight /></div>
          </div>
          : 
          <div className="section" onClick={navigate('/appointment?location=appointment')}><a href="#" className="btn-rounded w-button">{t('navbar.appointment')}</a></div>
        } */}
      </div>
      {/* <div className="testimonials-wrap">
          <h3 data-w-id="6cec38d4-b11f-39a1-9f6a-8ab9db7867a2" className="h3 h3--bold">{ t('home.reviews') }<br/></h3>
          <div data-animation="slide" data-duration="500" data-infinite="1" className="slider w-slider">
            <div className="mask w-slider-mask">
              <div className="slide w-slide">
                <div className="slider__cont-wrap">
                  <div className="slider__cont col__cont-wrap--vertical">
                    <div className="slider__circle-box"></div>
                    <h4 className="h4">Heather Clark<br/></h4>
                    <p className="p col__p">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.<br/><br/></p>
                  </div>
                </div>
              </div>
              <div className="slide w-slide">
                <div className="slider__cont-wrap">
                  <div className="slider__cont col__cont-wrap--vertical">
                    <div className="slider__circle-box"></div>
                    <h4 className="h4">Heather Clark<br/></h4>
                    <p className="p col__p">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.<br/><br/></p>
                  </div>
                </div>
              </div>
              <div className="slide w-slide">
                <div className="slider__cont-wrap">
                  <div className="slider__cont col__cont-wrap--vertical">
                    <div className="slider__circle-box"></div>
                    <h4 className="h4">Heather Clark<br/></h4>
                    <p className="p col__p">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.<br/><br/></p>
                  </div>
                </div>
              </div>
            </div>
            <div data-w-id="3d863302-df20-010d-7cff-301bf6a22cc2" className="arrow-box w-slider-arrow-left"></div>
            <div className="slider__arrow-wrap slider__arrow-wrap--right"><img src="images/arrow-right__white.svg" alt="" className="slider__arrow-img"/></div>
            <div className="slider__arrow-wrap slider__arrow-wrap--left"><img src="images/arrow-right__white.svg" alt="" className="slider__arrow-img slider__arrow-img--left"/></div>
            <div data-w-id="3d863302-df20-010d-7cff-301bf6a22cc4" className="arrow-box w-slider-arrow-right"></div>
            <div className="slide-nav w-slider-nav w-round"></div>
          </div>
          <div className="stars-box">
            <div className="stars"><img src="images/Path-Copy.svg" alt="" className="star-img"/><img src="images/Path-Copy.svg" alt="" className="star-img"/><img src="images/Path-Copy.svg" alt="" className="star-img"/><img src="images/Path-Copy.svg" alt="" className="star-img"/><img src="images/Group-4.svg" alt="" className="star-img"/></div>
            <h5 className="stars-title">{ `9.7/10 - 134 ${t('home.rating').toUpperCase()}` }</h5>
          </div>
        </div> */}
      {/* <div className="partners-wrap">
          <h4 className="h4 h4--blue">{ t('home.partners') }<br/></h4>
          <div className="partners__logo-wrap"><img src="images/legal-experts-logo.png" width="188" alt="" className="partner-logo" /></div>
        </div> */}
    </div>
  )
}

export default Home