import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckIcon from '@material-ui/icons/Check';
import 'moment/locale/fr'
import 'moment/locale/nl'
import { AddToCalendar } from './AddToCalendar'
import './AddToCalendar/styles.css'

import Collapse from '@material-ui/core/Collapse'
import { sortedArrayFromObject, currentLanguage, t } from '../utils'
import { updateAppointment } from '../services/database'
import FileInput from './FileInput';
import config from '../config.json'

const AppointmentBlock = (props) => {

  const { appointment, topics, questions, startLoading, stopLoading, passive, fetchAppointments } = props

  const maxFileSize = 5000000
  
  const fileInputRef = useRef(null)

  const [calendarEvent, setCalendarEvent] = useState(null)
  const [editing, setEditing] = useState(false)
  const [comment, setComment] = useState('')
  const [attachments, setAttachments] = useState([])


  useEffect(() => {
    const formattedStart = moment(appointment.date).locale(currentLanguage()).format("YYYY-MM-DDTHH:mm:ssZ")
    const formattedEnd = moment(appointment.date).locale(currentLanguage()).add(30, 'minutes').format("YYYY-MM-DDTHH:mm:ssZ")
    setCalendarEvent({
      name: t('ics.title'),
      details: t('ics.description'),
      location: '',
      startsAt: formattedStart,
      endsAt: formattedEnd,
      download: 'test'
    })
    setComment(appointment.comment || '')
    setAttachments(appointment.attachments || [])
  }, [appointment])


  const printDay = (date) => {
    return moment(date).locale(currentLanguage()).format('DD MMM')
  }

  const printTime = (date) => {
    return moment(date).locale(currentLanguage()).format("HH[h]mm")
  }

  const isFuture = () => {
    return appointment.date > moment().valueOf()
  }

  const openFileInput = () => {
    if(!!fileInputRef.current) {
      fileInputRef.current.click()
    } else if(!!fileInputRef.click) {
      fileInputRef.click()
    }
  }

  const onFileSubmitted = ({ file, name, type }) => {
    addFile({ file, name, type })
  }

  const addFile = (file) => {
    let att = attachments
    att.push(file)
    setAttachments([...att])
  }

  const removeAttachment = (index) => () => {
    let att = attachments
    att.splice(index, 1)
    setAttachments([...att])
  }

  const openAttachment = (url) => () => {
    window.open(url, '_blank')
  }

  const startEdit = () => {
    setEditing(true)
  }

  const cancelEdit = () => {
    setComment(props.appointment.comment || '')
    setAttachments(props.appointment.attachments || [])
    setEditing(false)
  }

  const confirmEdit = async () => {
    startLoading('updatingAppointment')
    let request = {
      comment
    }
    let result = await updateAppointment(request, attachments, props.appointment.id)
    if(result.error) {
      // console.log('update appointment error', result.error)
    } else {
      await fetchAppointments()
      setEditing(false)
    }
    stopLoading('updatingAppointment')
  }

  const openReceipt = () => {
    window.open(appointment.invoice ? appointment.invoice.url : appointment.receipt, '_blank')
  }

  const hasReport = () => {
    if(!appointment || !appointment.report) {
      return false
    }
    return true
  }

  const hasInvoice = () => {
    if(!appointment || !appointment.invoice) {
      return false
    }
    return true
  }

  const openReport = () => {
    if(!hasReport()) {
      return
    }
    window.open(appointment.report.url)
  }

  return (
    <div className="meet-box meet-box--margin">
      <div className="meet-box__first-row">
        <div className="date-box">
          <div className="date-title">{ printDay(appointment.date) }</div>
          <div className="date-title date-title--thin">{ printTime(appointment.date) }<br/></div>
        </div>
        <div className="meet-desc">
          <h6 className="h6 h6--blue">{ topics[appointment.topic]?.title[currentLanguage()] }<br/></h6>
          <h4 className="meet-h4">{ questions[appointment.question]?.text[currentLanguage()] }<br/></h4>
           { isFuture() && !!calendarEvent ? (
              <div className="calendar-button-start">
                <AddToCalendar event={calendarEvent} filename={t('ics.title')}>
                  <div className="text text--small add-to-calendar-label">{ t('dashboard.save_to_calendar')}</div>
                </AddToCalendar>
              </div>
            ) : null }
            
            { hasInvoice() ? (
              <div className="calendar-button-start">
                <div className="chq-atc--button">
                    <button type="button" className="chq-atc--button">
                      <svg xmlns="http://www.w3.org/2000/svg" height="240" viewBox="0 0 24 24" width="20">
                        <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
                      </svg>
                      <div className="text text--small add-to-calendar-label" onClick={openReceipt}>{ t('dashboard.download_invoice')}<br/></div>
                    </button>
                </div>
              </div>
            ) : null }
            { hasReport() ? (

              <div className="calendar-button-start">
                <div className="chq-atc--button">
                    <button type="button" className="chq-atc--button">
                      <svg xmlns="http://www.w3.org/2000/svg" height="240" viewBox="0 0 24 24" width="20">
                      <path fill="none" d="M17.206,5.45l0.271-0.27l-4.275-4.274l-0.27,0.269V0.9H3.263c-0.314,0-0.569,0.255-0.569,0.569v17.062
                        c0,0.314,0.255,0.568,0.569,0.568h13.649c0.313,0,0.569-0.254,0.569-0.568V5.45H17.206z M12.932,2.302L16.08,5.45h-3.148V2.302z
                        M16.344,17.394c0,0.314-0.254,0.569-0.568,0.569H4.4c-0.314,0-0.568-0.255-0.568-0.569V2.606c0-0.314,0.254-0.568,0.568-0.568
                        h7.394v4.55h4.55V17.394z"></path>
                      </svg>
                      <div className="text text--small add-to-calendar-label" onClick={openReport}>{ t('dashboard.download_report')}<br/></div>
                    </button>
                </div>
              </div>
            ) : null }
          </div>
        </div>
      { isFuture() && !passive ? (
      <div className="request-form w-form">
        <Collapse in={editing}>
          <label htmlFor="Commentaire" className="form__label">{ t('dashboard.comment' )}</label>
          <div className="textarea-wrap"><textarea value={comment} onChange={e => setComment(e.target.value)} name="Commentaire" maxLength="5000" id="Commentaire" data-name="Commentaire" className="textarea w-input"></textarea></div>
          <label htmlFor="Commentaire" className="form__label">{ t('dashboard.attachments') }<br/></label>
          <div className="request-btn-wrap appointment-block-att-button">            
            <Grid container className="attachments-grid" direction="column">
              { attachments.map((att, ai) => {
                return (
                  <Grid key={`att_${ai}`} item className="attachment-thumb">
                    <span onClick={att.url ? openAttachment(att.url) : () => {}} className={`attachment-thumb-label ${att.url ? 'link' : ''}`}>{ `${att.name}` }</span>
                    { att.url ? (
                      <CheckIcon color="primary" fontSize="small" className="attachment-thumb-icon" />
                    ) : (
                      <CancelIcon onClick={removeAttachment(ai)} className="attachment-thumb-icon delete" color="secondary" fontSize="small" />
                    ) }
                  </Grid>
                )
              })}
            </Grid>
            <div onClick={openFileInput} data-w-id="e1603f77-2ed4-59bb-8690-47fedecd232f" className="request-cta">
              <div className="text text--small">{ t('dashboard.upload_file')}<br/></div><img src="images/🎨-Style.svg" alt="" className="arrow-up-img"/>
            </div>
          </div>
        </Collapse>
        <div className="buttons-row">
          <div className={`green-button ${editing ? 'full' : ''}`}>
            <h6 onClick={editing ? confirmEdit : startEdit} className="h6 h6--green">{ editing ? t('general.save') : t('general.edit') }<br/></h6>
          </div>
          { editing ? (
            <h6 onClick={cancelEdit} className="h6 h6--gray button-second">{ t('general.cancel') }<br/></h6>
          ) : null }
        </div>
      </div>
      ) : null }
      <FileInput id="fileInput" fileInputRef={fileInputRef} maxFileSize={maxFileSize} onFileSubmitted={onFileSubmitted} accept="image/png, image/jpg, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/rtf, application/vnd.oasis.opendocument.text" />
    </div>
  )
}

export default AppointmentBlock