import React from 'react'
import Lottie from 'react-lottie'
import { HashLink } from 'react-router-hash-link'

import * as calendarAnimation from '../../animations/calendar.json'
import * as questionAnimation from '../../animations/question.json'
import * as phoneAnimation from '../../animations/phone.json'
import { t } from '../../utils'

const calendarAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: calendarAnimation.default
}

const questionAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: questionAnimation.default
}

const phoneAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: phoneAnimation.default
}

const Features = ({ hideVideoButton = false, hideHead = false, version = 'v1', history, showToFaqBtn, showToFaqBtnInsideActions, id }) => {
  const scrollToVideoSection = () => {
    const videoSection = document.querySelector('#video-section')
    if(videoSection) {
      window.scrollTo({ top: videoSection.getBoundingClientRect().top + window.scrollY, behavior: 'smooth' })
    }
  }

  return (
    <div className={version === 'v2' ? "features features--2" : "features"} id={id}>
      {!hideHead && <div className="features__head">
        <h3>{t('landing.features_title')}</h3>
      </div>}
      <div className="features__boxes">
        <div className="features__box">
          <div className="features__box_lottie">
            <div>
              <Lottie
                options={questionAnimationOptions}
                isStopped={false}
                isPaused={false}
                width={154}
                height={154} 
              />
            </div>
          </div>
          <h5>{t('landing.feature_1_heading')}</h5>
          <p>{t('landing.feature_1_text')}</p>
        </div>
        <div className="features__box">
          <div className="features__box_lottie">
            <div>
              <Lottie
                options={calendarAnimationOptions}
                isStopped={false}
                isPaused={false}
                width={154}
                height={154} 
              />
            </div>
          </div>
          <h5>{t('landing.feature_2_heading')}</h5>
          <p>{t('landing.feature_2_text')}</p>
        </div>
        <div className="features__box">
          <div className="features__box_lottie">
            <div>
              <Lottie
                options={phoneAnimationOptions}
                isStopped={false}
                isPaused={false}
                width={154}
                height={154} 
              />
            </div>
          </div>
          <h5>{t('landing.feature_3_heading')}</h5>
          <p>{t('landing.feature_3_text')}</p>
        </div>
      </div>
      <div className="features__actions">
        <button className="button" onClick={() => history.push({ pathname: '/appointment', search: 'location=skip&topic=other&question=other' })}>{t('landing.feature_btn_text')}</button>
        {!hideVideoButton && <button className="button button--white" onClick={scrollToVideoSection}>{t('landing.to_video_btn_text')}</button>}
        {showToFaqBtnInsideActions && <div className="to-faq-btn-wrapper">
          <HashLink className="button button--white" to="/#home-categories">{t('landing.to_faq_btn')}</HashLink>
        </div>}
      </div>
      {showToFaqBtn && <div className="to-faq-btn-wrapper">
        <HashLink className="button button--white" to="/#home-categories">{t('landing.to_faq_btn')}</HashLink>
      </div>}
    </div>
  )
}

export default Features