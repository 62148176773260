import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { sortedArrayFromObject, printDate, t } from '../utils'
import AppointmentBlock from './AppointmentBlock'

const ProfileTabAppointments = (props) => {

  const { topics, questions, startLoading, stopLoading, fetchAppointments } = props

  const [futureAppointments, setFutureAppointments] = useState([])
  const [pastAppointments, setPastAppointments] = useState([])

  useEffect(() => {
    // url query changed
    let appArray = sortedArrayFromObject(props.appointments, 'date', false)
    let fa = []
    let pa = []
    for(let i in appArray) {
      let app = appArray[i]
      if(app.date > moment().valueOf()) {
        fa.push(app)
      } else {
        pa.push(app)
      }
    }
    fa.reverse()
    setFutureAppointments([...fa])
    setPastAppointments([...pa])
  }, [props.appointments])

  return (
    <div className="tab__help-wrap">
      <h4 className="tab__title">{ t('dashboard.future_appointments') }</h4>
      { futureAppointments.length > 0 ? futureAppointments.map((appointment, ai) => {
        return (
          <AppointmentBlock fetchAppointments={fetchAppointments} startLoading={startLoading} stopLoading={stopLoading} key={`future_appointment_${ai}`} appointment={appointment} topics={topics} questions={questions} />
        )
      }) : (
        <span>{ t('dashboard.no_appointment') }</span>
      ) }
      <br/>
      <h4 className="tab__title">{ t('dashboard.past_appointments')}</h4>
      { pastAppointments.length > 0 ? pastAppointments.map((appointment, ai) => {
        return (
          <AppointmentBlock fetchAppointments={fetchAppointments} startLoading={startLoading} stopLoading={stopLoading} key={`past_appointment_${ai}`} appointment={appointment} topics={topics} questions={questions} />
        )
      }) : (
        <span>{ t('dashboard.no_appointment') }</span>
      )}
    </div>
  )
}

export default ProfileTabAppointments
