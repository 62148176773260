import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import config from '../config.json'
import { currentUser } from '../services/auth'
import { fetchPaymentIntent } from '../services/functions'
import { t } from '../utils' 
import { animateScroll as scroll } from 'react-scroll'
import { listenForAppointment } from '../services/database';

const CheckoutForm = (props) => {

  const { onSuccess, onError, startLoading, stopLoading, showAuth, onSubmit, userData, voucherCode } = props

  const [draft, setDraft] = useState(null)
  const [saveCard, setSaveCard] = useState(false)
  const [currentCard, setCurrentCard] = useState(null)
  const [useNewCard, setUseNewCard] = useState(true)
  const [intentResult, setIntentResult] = useState(null)

  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    if(userData.cards && userData.cards.length > 0) {
      // set saved card if there's any

      // todo setup select with multiple cards
      setCurrentCard(userData.cards[0])
      setUseNewCard(false)
    } else {
      setCurrentCard(null)
    }
  }, [userData])

  const submitPayment = async () => {
    setTimeout(() => {
      scroll.scrollTo(250)
    }, 1);

    if (!stripe || !elements) {
      return
    }

    if(!currentUser()) {
      showAuth('login', () => {
        submitPayment()
      }, () => {
        submitPayment()
      })
      return
    }
    
    let appointmentDraftData
    if(!draft) {
      appointmentDraftData = await onSubmit()
      setDraft(appointmentDraftData)
    } else {
      appointmentDraftData = draft
    }

    startLoading('checkout')

    let secret
    let appointmentId

    if(!intentResult) {
      let paymentIntentResult = await fetchPaymentIntent(appointmentDraftData, voucherCode, !useNewCard ? currentCard?.id || '' : '')
      
      if(paymentIntentResult.error) {
        console.log('payment intent error', paymentIntentResult)
        stopLoading('checkout')
        return
      }
      setIntentResult(paymentIntentResult)

      appointmentId = paymentIntentResult.appointmentId
      secret = paymentIntentResult.clientSecret
    } else {
      appointmentId = intentResult.appointmentId
      secret = intentResult.clientSecret
    }

    let paymentData = {}
    if(!currentCard || useNewCard) {
      paymentData = {
        payment_method: {
          card: elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement),
          billing_details: {
            name: `${userData?.name?.first || ''} ${userData?.name?.last || ''}`,
          },
        }
      }
      if(saveCard) {      
        paymentData.setup_future_usage = 'on_session'
      }
    }
    try {
      const result = await stripe.confirmCardPayment(secret, paymentData)

      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        console.log('payment error', result)
        stopLoading('checkout')
        onError(result.error)
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          // onSuccess()
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
          // todo listen for payment document update instead of checking success here
          let listener = listenForAppointment(appointmentId, (appointmentResult) => {
            if(appointmentResult && !appointmentResult.error) {
              clearInterval(listener)
              onSuccess()
              stopLoading('checkout')
            }
          })
        } else {
          console.log('payment result status not successful', result)
          stopLoading('checkout')
        }
      }    
    } catch(err) {
      console.log("stripe confirm payment err")
      console.log(err)
      stopLoading('checkout')
      onError(err)
    }
  }

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#3ea447",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  }

  return (
    <div className="checkout-form">
      { (!currentCard || useNewCard) ? (
        <div>
          <h3 className="payment__h3 h3--bold">{ t('checkout.add_card') }<br/></h3>          
          <div className="form__field-wrap payment"><label htmlFor="Numero-de-carte-2" className="form__label">{ t('checkout.card_number') }</label><CardNumberElement options={CARD_ELEMENT_OPTIONS} type="text" className="form__text-field w-input" maxLength="256" name="Numero-de-carte-2" data-name="Numero De Carte 2" id="Numero-de-carte-2" required=""/></div>
          <div className="form__row payment">
            <div className="form__field-wrap form__field-wrap--left"><label htmlFor="Date-d-expiration-2" className="form__label">{ t('checkout.exp_date') }</label><CardExpiryElement options={CARD_ELEMENT_OPTIONS} className="form__text-field w-input" /></div>
            <div className="form__field-wrap form__field-wrap--right"><label htmlFor="Code-de-s-curit-2" className="form__label">{ t('checkout.code') }</label><CardCvcElement options={CARD_ELEMENT_OPTIONS} className="form__text-field w-input" /></div>
          </div>

          <label className="w-checkbox checkbox-field">
            <div className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox ${saveCard ? 'w--redirected-checked' : ''}`}></div>
            <input type="checkbox" id="checkbox" name="checkbox" data-name="Checkbox" required="" checked={saveCard} onChange={e => setSaveCard(e.target.checked)} style={{ opacity:0, position: 'absolute', zIndex: -1 }}/>
            <span className="checkbox-label w-form-label">{ t('checkout.save_card') }<br/></span>
          </label>
        </div> 
      ) : (        
        <div className="form__row form__row--pt" >
          <div className="form__img-t-wrap"><img src="images/visa.svg" alt="" className="form__img form__img--position" />
            <div className="form__text">{ `${currentCard.brand} ---- ${currentCard.last4}` }<br/></div>
          </div>
        </div>
      ) }
      
      <div onClick={submitPayment} className="form__btn-wrap checkout_button"><input type="submit" value={(!currentCard || useNewCard) && saveCard ? t('checkout.save_card_button') : t('checkout.use_card')} className="form__btn payment w-button "/></div>
      { currentCard ? (
        // <div className="request-btn-wrap mt-2"><input type="submit" value="" data-wait="Please wait..." className="request-btn w-button"/>
        //   <div className="request-cta checkout mt-2" onClick={() => setUseNewCard(!useNewCard)} >
        //     <div className="text text--small">{ !useNewCard ? t('checkout.new_card') : t('checkout.use_saved_card')  }</div>
        //   </div>
        // </div>
        <div className="form__btn-wrap checkout_button_other_card-wrap">
          <div onClick={() => setUseNewCard(!useNewCard)} className="checkout_button_other_card link-box link-box--submit w-inline-block ">
            <p className="link-box__title">{ !useNewCard ? t('checkout.new_card') : t('checkout.use_saved_card')  }<br/></p>
          </div>
        </div>
      ) : null }
    </div>
  )
}

export default CheckoutForm