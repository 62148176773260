import fetch from 'node-fetch'
import formData from 'form-data'
import firebase from 'firebase'
import { currentUserUid, userIdToken } from './auth'

import config from '../config.json'

import { baseUrl } from './api'

import moment from 'moment'

const ENVIRONMENTS_COL = 'environments'
const APPOINTMENTS_COL = 'appointments'
const USERS_COL = 'users'
const TOPICS_COL = 'topics'
const QUESTIONS_COL = 'questions'

const apiUrl = `${baseUrl()}/api_${config.environment}`

// --MARK-- DATABASE INTERACTION

const GET_COL_ACTION = 'getCollection'
const GET_DOC_ACTION = 'getDocument'
const CREATE_ACTION = 'createDocument'
const CREATE_SPECIFIC_ACTION = 'createSpecificDocument'
const UPDATE_ACTION = 'updateDocument'
const DELETE_ACTION = 'deleteDocument'

const QUERY_OPERATOR_EQUAL = 'EQUAL'
const QUERY_VALUE_STRING = 'stringValue'

async function getCollection(path, queryParams) {
  let idToken = await userIdToken()
  let body = {
    action: GET_COL_ACTION,
    path,
    idToken,
    queryParams
  }
  let result = await post(apiUrl, body)
  return result
}

async function getDocument(path) {
  let idToken = await userIdToken()
  let body = {
    action: GET_DOC_ACTION,
    path,
    idToken
  }
  let result = await post(apiUrl, body)
  return result
}


async function createSpecificDocument(path, data) {
  let idToken = await userIdToken()
  const timestamp = moment().valueOf()
  data.meta = {
    created: timestamp,
    updated: timestamp
  }
  let body = {
    action: CREATE_SPECIFIC_ACTION,
    path,
    idToken,
    data
  }
  let result = await post(apiUrl, body)
  return result
}

async function createDocument(path, data) {
  let idToken = await userIdToken()
  const timestamp = moment().valueOf()
  data.meta = {
    created: timestamp,
    updated: timestamp
  }
  let body = {
    action: CREATE_ACTION,
    path,
    idToken,
    data
  }
  let result = await post(apiUrl, body)
  return result
}

async function updateDocument(path, data) {
  let idToken = await userIdToken()
  const timestamp = moment().valueOf()
  if(!data.meta) {
    data.meta = {}
  }
  data.meta.updated = timestamp
  let body = {
    action: UPDATE_ACTION,
    path,
    idToken,
    data
  }
  let result = await post(apiUrl, body)
  return result
}

async function deleteDocument(path) {
  let idToken = await userIdToken()
  let body = {
    action: DELETE_ACTION,
    path,
    idToken
  }
  let result = await post(apiUrl, body)
  return result
}

// --MARK-- API COMMUNICATION

function post(url, body) {
  return new Promise(resolve => {
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => res.json())
    .then(body => {
      resolve(body)
    }).catch(err => {
      console.log('post error', err)
    })
  })
}

// --MARK-- ACTUAL FETCHERS AND UPDATES



async function fetchTopics() {
  return await getCollection(`${ENVIRONMENTS_COL}/{environment}/${TOPICS_COL}`)
  // return await getCollection(`${ENVIRONMENTS_COL}/${ENV}/${TOPICS_COL}`)
}

async function fetchQuestions() {
  return await getCollection(`${ENVIRONMENTS_COL}/{environment}/${QUESTIONS_COL}`)
  // return await getCollection(`${ENVIRONMENTS_COL}/${ENV}/${QUESTIONS_COL}`)
}


async function fetchPartner() {
  return await getDocument(`${ENVIRONMENTS_COL}/{environment}`)
  // return await getDocument(`${ENVIRONMENTS_COL}/${ENV}`)
}

async function fetchUserData() {
  const uid = currentUserUid()
  if(!uid) {
    return { error: { message: 'User not authenticated'} }
  }
  
  return getDocument(`${ENVIRONMENTS_COL}/{environment}/${USERS_COL}/${uid}`)
  // return getDocument(`${ENVIRONMENTS_COL}/${ENV}/${USERS_COL}/${uid}`)
}

function listenForAppointment(appointmentId, listener) {
  let path = `${ENVIRONMENTS_COL}/${config.environment}/${APPOINTMENTS_COL}/${appointmentId}`
  return setInterval(async () => {
    let result = await getDocument(path)
    listener(result)
  }, 1500)
}

const updateUserData = async (data) => {
  const uid = currentUserUid()
  if(!uid) {
    return { error: { message: 'User not authenticated'} }
  }
  let result = await updateDocument(`${ENVIRONMENTS_COL}/{environment}/${USERS_COL}/${currentUserUid()}`, data)
  // let result = await updateDocument(`${ENVIRONMENTS_COL}/${ENV}/${USERS_COL}/${currentUserUid()}`, data)
  if(result.error) {
    if(result.error.code === 5) {
      // document doesnt exist
      result = await createSpecificDocument(`${ENVIRONMENTS_COL}/{environment}/${USERS_COL}/${currentUserUid()}`, data)
      // result = await createSpecificDocument(`${ENVIRONMENTS_COL}/${ENV}/${USERS_COL}/${currentUserUid()}`, data)
      return result
    } else {
      return result
    }
  } else {
    return result
  }
}

async function fetchAppointments() {
  const uid = currentUserUid()
  if(!uid) {
    return { error: { message: 'User not authenticated'} }
  }
  return await getCollection(`${ENVIRONMENTS_COL}/{environment}/${APPOINTMENTS_COL}`, [{ key: 'author', value: uid, op: '==' }] )
  // return await getCollection(`${ENVIRONMENTS_COL}/${ENV}/${APPOINTMENTS_COL}`, [{ key: 'author', value: uid, op: '==' }] )
} 

async function updateAppointment(data, attachments, appointmentId) {
  data.author = currentUserUid()
  if(!!attachments && attachments.length > 0) {
    data.attachments = []
    for(let i in attachments) {
      let att = attachments[i]
      if(att.file) {
        let fileName = appointmentId ? `appointment_${appointmentId}_attachment_${att.name}` : `attachment_${att.name}_${moment().valueOf()}`
        let uploadResult = await uploadFile(att.file, fileName, `users/${currentUserUid()}/attachments`)
        let responseJson = await uploadResult.json()
        if(!responseJson.error) {
          data.attachments.push({
            name: att.name,
            url: responseJson.url
          })
        } else {
          console.log('Upload error', responseJson)
        }
      } else if(att.url) {
        data.attachments.push(att)
      }
    }
  }
  if(!!appointmentId) {
    return await updateDocument(`${ENVIRONMENTS_COL}/{environment}/${APPOINTMENTS_COL}/${appointmentId}`, data)
    // return await updateDocument(`${ENVIRONMENTS_COL}/${ENV}/${APPOINTMENTS_COL}/${appointmentId}`, data)
  } else {
    return await createDocument(`${ENVIRONMENTS_COL}/{environment}/${APPOINTMENTS_COL}`, data)
    // return await createDocument(`${ENVIRONMENTS_COL}/${ENV}/${APPOINTMENTS_COL}`, data)
  }
}


const uploadFile = async (file, filename, path) => {

  let form = new formData()
  form.append('file', file, filename);
  form.append('path', path)
  form.append('environment', config.environment)

  let result = await fetch(`${baseUrl()}/uploadAttachment`, {
    method: 'POST',
    contentType: 'multipart/form-data',
    body: form
  });
  return result
  

  // try {
  //   let fileRef = firebase.storage().ref().child(`${path}/${name}`)
  //   // let fileRef = firebase.storage().ref().child(`/${handle}/${entityId}_logo.png`)
  //   let parts = data.split(',')
  //   let base64data
  //   if(parts.length === 2) {
  //     base64data = parts[1]
  //   } else {
  //     base64data = data
  //   }
  //   await fileRef.putString(base64data, 'base64', { contentType: type })
  //   let downloadUrl = await fileRef.getDownloadURL()
  //   return { url: downloadUrl }
  // } catch(err) {
  //   console.log('image upload error', err)
  //   return { error: err }
  // }
}

export {
  fetchPartner,
  fetchUserData,
  fetchTopics,
  fetchQuestions,
  fetchAppointments,
  updateAppointment,
  updateUserData,
  uploadFile,
  listenForAppointment
}