import React, { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { t, constants } from '../utils'
import config from '../config.json'

const stripePromise = loadStripe(constants.stripe_key[config.environment])

const APStepCheckout = (props) => {

  const { startLoading, stopLoading, showSnackbar, onCheckoutSuccess, showAuth, onCheckoutSubmit, userData, voucherCode } = props


  const onCheckoutError = (error) => {
    showSnackbar({ text: error.message, color: 'error' })
  }

  return (
    <Elements stripe={stripePromise}>
      <div className="payment-wrap">
      <h3 data-w-id="0e48b1ac-9288-3d3d-ae7b-164a2f0f8aa4" className="h3 h3--bold h3--blue">{ `${t('appointment.payment_title')}` }<br/></h3>
        <div className="container container--hero payment">
          <CheckoutForm
              startLoading={startLoading}
              stopLoading={stopLoading}
              onSuccess={onCheckoutSuccess}
              onError={onCheckoutError}
              showAuth={showAuth}
              onSubmit={onCheckoutSubmit}
              userData={userData}
              voucherCode={voucherCode}
            />
        </div>
      </div>
    </Elements>
  )
}

export default APStepCheckout