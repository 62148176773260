import React from 'react'

const Loader = ({ small, primary, normal, normalWhite, mini, normalSuccess }) => {
  let loaderClasses = 'loader'

  if(small) {
    loaderClasses += ' loader--small'
  }

  if(primary) {
    loaderClasses += ' loader--primary'
  }

  if(normal) {
    loaderClasses += ' loader--normal'
  }

  if(normalWhite) {
    loaderClasses += ' loader--normal-white'
  }

  if(mini) {
    loaderClasses += ' loader--mini'
  }

  if(normalSuccess) {
    loaderClasses += ' loader--normal-success'
  }

  return(
    <div className={loaderClasses}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default Loader