import React, { useState, useEffect } from 'react'
import AppointmentBlock from './AppointmentBlock'
import moment from 'moment'
import { sortedArrayFromObject, t, currentLanguage } from '../utils'

const ProfileTabDashboard = (props) => {

  const { topics, questions, fetchAppointments, partner, refreshInteractions } = props
  
  const [upcomingAppointment, setUpcomingAppointment] = useState(null)
  const [experts, setExperts] = useState([])
  const [helpQuestions, setHelpQuestions] = useState([])

  const navigate = (path) => () => {
    const { history } = props
    history.push(path)
  }

  useEffect(() => {
    if(!partner) {
      return
    }
    if(partner.experts) {
      setExperts(partner.experts)
    }
    if(partner.faqs) {
      setHelpQuestions(partner.faqs)
    }
  }, [partner])

  useEffect(() => {
    refreshInteractions()
  }, [helpQuestions, experts])

  useEffect(() => {
    // url query changed
    let appArray = sortedArrayFromObject(props.appointments, 'date', true)
    let futureAppointments = []
    for(let i in appArray) {
      let app = appArray[i]
      if(app.date > moment().valueOf()) {
        futureAppointments.push(app)
      }
    }
    if(futureAppointments.length > 0) {
      setUpcomingAppointment({...futureAppointments[0]})
    }
  }, [props.appointments])

  const renderQuestion = (question, qi) => {
    return (
      <div key={`faq_${qi}`} className="accordion-wrapper">
        <div className="tab__help-content">
          <div data-w-id="dd2cc323-b524-7451-102d-767c73b2af80" className="help-title">
            <h4 className="help-h4">{ question.question[currentLanguage()] } </h4><img src="images/right-arrow.svg" alt="right orange arrow" width="10" height="16" className="faq-down-arrow"/></div>
          <div className="help-text-wrap">
            <div className="help-text">{ question.answer[currentLanguage()] }</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="tab__content-wrap">
      <div className="tab__help-wrap">
        <h4 className="tab__title">{ t('dashboard.next_appointment')}<br/></h4>
        { upcomingAppointment ? (
          <AppointmentBlock fetchAppointments={fetchAppointments} appointment={upcomingAppointment} passive topics={topics} questions={questions} />
        ) : <span> { t('dashboard.no_appointment') } </span> }
        <h4 className="tab__title _2">{ t('navbar.help') }</h4>
        { helpQuestions.map(renderQuestion) }
        
        <h4 className="tab__title _2">{ t('dashboard.experts') }<br/></h4>
        <div onClick={navigate('/experts')} className="tab__circles-wrap">
          { experts.map((expert, ei) => {
            return (
              <div key={`expert_${ei}`} className="tab__circle-box" style={{ backgroundImage: `url('${expert.image}')`}} />
            )
          }) }
        </div>
      </div>
    </div>
  )

}

export default ProfileTabDashboard
