import React from 'react'

import { t } from '../../utils'

const TextSectionTwo = () => {
  return (
    <div className="text-section text-section--2">
      <div className="text-section__head">
        <h3>{t('landing.text_section_2_title').split('\n').map((text, i) => <span key={i}>{text}</span>)}</h3>
      </div>
      <div className="text-section__body">
        <p>{t('landing.text_section_desc').split('\n').map((text, i) => <span key={i}>{text}</span>)}</p>
      </div>
    </div>
  )
}

export default TextSectionTwo