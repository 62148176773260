import React from 'react'

import Intro from '../components/landing/Intro'
import Experts from '../components/landing/Experts'
import Features from '../components/landing/Features'
import Packages from '../components/landing/Packages'
import VideoSection from '../components/landing/VideoSection'
import Testimonials from '../components/landing/Testimonials'

const LandingOne = (props) => {
  return (
    <div className="landing">
      <Intro history={props.history} />
      <Experts selectedLocale={props.selectedLocale} />
      <Features history={props.history} showToFaqBtn id="features-section-1" />
      <Packages history={props.history} />
      <VideoSection />
      <Testimonials selectedLocale={props.selectedLocale} />
    </div>
  )
}

export default LandingOne