import React, { useState, useEffect } from 'react'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeftRounded'
import ChevronRightIcon from '@material-ui/icons/ChevronRightRounded'

import { t } from '../../utils'

SwiperCore.use([Navigation])

const Experts = ({ selectedLocale }) => {
  const [swiper, setSwiper] = useState(null)
  const [activeSlide, setActiveSlide] = useState(0)
  const [experts, setExperts] = useState([
    { name: t('landing.expert_1_name'), text: t('landing.expert_1_text') },
    { name: t('landing.expert_2_name'), text: t('landing.expert_2_text') },
    { name: t('landing.expert_3_name'), text: t('landing.expert_3_text') },
    { name: t('landing.expert_4_name'), text: t('landing.expert_4_text') },
    { name: t('landing.expert_5_name'), text: t('landing.expert_5_text') },
    { name: t('landing.expert_6_name'), text: t('landing.expert_6_text') },
    { name: t('landing.expert_7_name'), text: t('landing.expert_7_text') },
    { name: t('landing.expert_8_name'), text: t('landing.expert_8_text') },
  ])

  const updateExperts = () => {
    setExperts([
      { name: t('landing.expert_1_name'), text: t('landing.expert_1_text') },
      { name: t('landing.expert_2_name'), text: t('landing.expert_2_text') },
      { name: t('landing.expert_3_name'), text: t('landing.expert_3_text') },
      { name: t('landing.expert_4_name'), text: t('landing.expert_4_text') },
      { name: t('landing.expert_5_name'), text: t('landing.expert_5_text') },
      { name: t('landing.expert_6_name'), text: t('landing.expert_6_text') },
      { name: t('landing.expert_7_name'), text: t('landing.expert_7_text') },
      { name: t('landing.expert_8_name'), text: t('landing.expert_8_text') },
    ])
  }

  useEffect(() => {
    if(swiper) {
      updateExperts()
    }
  }, [selectedLocale])

  return (
    <div className="experts">
      <div className="experts__overlay"></div>
      <div className="experts__body">
        <h2 className="heading-1">{t('landing.experts_title')}</h2>
        <h3>{t('landing.experts_subtitle')}</h3>
        <div className="experts__slider">
          <Swiper
            onInit={(sw) => setSwiper(sw)}
            navigation={{
              prevEl: '.experts__slider_navigation-prev',
              nextEl: '.experts__slider_navigation-next'
            }}
            slidesPerView={1}
            spaceBetween={30}
            onSlideChange={(sw) => setActiveSlide(sw.activeIndex)}
          >
            {experts.map((expert, i) => (
              <SwiperSlide key={i}>
                <div className="experts__slider_slide">
                  <h4>{expert.name}</h4>
                  <p>{expert.text}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="experts__slider_navigation">
            <button className="icon-button experts__slider_navigation-prev"><ChevronLeftIcon /></button>
            <button className="icon-button experts__slider_navigation-next"><ChevronRightIcon /></button>
          </div>
        </div>
        <div className="experts__slider-pagination">
          {experts.map((_, i) => <span key={i} className={activeSlide === i ? "bullet bullet-active" : "bullet"} onClick={() => swiper.slideTo(i)}></span>)}
        </div>
      </div>
    </div>
  )
}

export default Experts