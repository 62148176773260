import React from 'react'

import ExpertsTwo from '../components/landing/ExpertsTwo'
import TextSectionTwo from '../components/landing/TextSectionTwo'
import Features from '../components/landing/Features'
import FAQ from '../components/landing/FAQ'
import VideoSection from '../components/landing/VideoSection'
import Testimonials from '../components/landing/Testimonials'
import Packages from '../components/landing/Packages'

const LandingThree = (props) => {
  return (
    <div className="landing">
      <ExpertsTwo selectedLocale={props.selectedLocale} history={props.history} />
      <TextSectionTwo />
      <Features hideHead version="v2" history={props.history} id="features-section-3" hideVideoButton />
      <FAQ questions={props.questions} history={props.history} />
      <VideoSection />
      <Testimonials selectedLocale={props.selectedLocale} />
      <Packages history={props.history} />
    </div>
  )
}

export default LandingThree