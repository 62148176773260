import React from 'react'
import { t } from '../utils'

const FileInput = (props) => {
  const { id, fileInputRef, onFileSubmitted, accept, maxFileSize } = props
  let acceptArray = accept.split(',')
  for(let i in acceptArray) {
    acceptArray[i] = acceptArray[i].trim()
  }

  const onChange = (e) => {
    let files = e.target.files
    if (files && files[0]) {
      var reader = new FileReader();

      reader.onload = (e) => {
        const file = files[0]
        if (file.size > maxFileSize) {
          alert(t('error.file_too_big'))
          return
        }
        let type = file.type
        if(!acceptArray.includes(type)) {
          alert(`Invalid file type, allowed types are ${accept}`)
          return
        }
        const name = file.name
        onFileSubmitted({ file: file, name: name, type: type })
        fileInputRef.current.value = ""
      }
      reader.onerror = (err) => {
        console.log('reader on error', err)
      }
      reader.readAsDataURL(files[0]);
    } else {
      // no files
    }
  }

  return (
    <input id={id || "fileInput"} ref={fileInputRef} hidden type='file' onChange={onChange} accept={accept} />
  )
}

export default FileInput