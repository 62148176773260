import React, { useState, useEffect } from 'react'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeftRounded'
import ChevronRightIcon from '@material-ui/icons/ChevronRightRounded'
import GradeIcon from '@material-ui/icons/GradeRounded'

import { t } from '../../utils'

SwiperCore.use([Navigation])

const Testimonials = ({ selectedLocale }) => {
  const [testimonials, setTestimonials] = useState([
    { author: t('landing.testimonial_1_author'), text: t('landing.testimonial_1_text'), rating: 5 },
    { author: t('landing.testimonial_2_author'), text: t('landing.testimonial_2_text'), rating: 5 },
    { author: t('landing.testimonial_3_author'), text: t('landing.testimonial_3_text'), rating: 5 },
  ])

  const updateTestimonials = () => {
    setTestimonials([
      { author: t('landing.testimonial_1_author'), text: t('landing.testimonial_1_text'), rating: 5 },
      { author: t('landing.testimonial_2_author'), text: t('landing.testimonial_2_text'), rating: 5 },
      { author: t('landing.testimonial_3_author'), text: t('landing.testimonial_3_text'), rating: 5 },
    ])
  }

  useEffect(() => {
    updateTestimonials()
  }, [selectedLocale])

  return (
    <div className="testimonials">
      <div className="testimonials__inner">
        <div className="testimonials__head">
          <h3>{t('landing.testimonials_title')}</h3>
        </div>
        <div className="testimonials__slider">
          <button className="icon-button testimonials__slider_navigation-prev"><ChevronLeftIcon /></button>
          <button className="icon-button testimonials__slider_navigation-next"><ChevronRightIcon /></button>
          <Swiper
            navigation={{
              prevEl: '.testimonials__slider_navigation-prev',
              nextEl: '.testimonials__slider_navigation-next'
            }}
            slidesPerView={1}
            spaceBetween={10}
            breakpoints={{
              767: {
                slidesPerView: 2,
                spaceBetween: 30
              },
              991: {
                slidesPerView: 3,
                spaceBetween: 60
              }
            }}
          >
            {testimonials.map((testimonial, i) => {
              return <SwiperSlide key={i}>
                <div className="testimonials__slide">
                  <p className="author">{testimonial.author}</p>
                  <div className="text">
                    <p>{testimonial.text}</p>
                  </div>
                  <div className="rating">
                    {Array(testimonial.rating).fill(<GradeIcon />)}
                  </div>
                </div>
              </SwiperSlide>
            })}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Testimonials