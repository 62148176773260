import React, { useState, useContext } from 'react'
import { ChevronRight } from '@material-ui/icons'

import { currentLanguage, t } from '../utils'
import { GlobalContext } from '../context'

const ExpertCard = ({ expert, navigate }) => {
  const { setSelectedGlobalExpert } = useContext(GlobalContext)
  const [expanded, setExpanded] = useState(false)

  const renderText = () => {
    let text = expert.bio[currentLanguage()]
    if(!text) {
      return ''
    }
    if(text.length > 100 && !expanded) {
      text = text.slice(0, 100)
    }
    return text
  }

  const shouldShowExpand = () => {
    let text = expert.bio[currentLanguage()]
    if(!text) {
      return false
    }
    if(text.length > 100 && !expanded) {
      return true
    }
    return false
  }

  // Toggle text
  const handleToggleText = (e) => {
    e.preventDefault()
    setExpanded(!expanded)
  }

  // On expert select
  const handleExpertSelect = (e) => {
    e.preventDefault()
    navigate(`/appointment?location=appointment&expert=${expert.name}&topic=other&question=other`)()
    setSelectedGlobalExpert(expert)
  }

  return (
    <div className="expert-card">
      <div className="expert-card__img" style={{backgroundImage: `url('${expert.image}')`}}></div>
      <div className="expert-card__info">
        <h4>{expert.name}</h4>
        <div className="expert-card__info_desc">
          <p>{renderText()} {shouldShowExpand() && '...'}</p>
        </div>
        {expert.bio[currentLanguage()]?.length > 100 &&(
          <div className="btn-wrapper">
            <a href="/#" className="read-more" onClick={handleToggleText}>{t('general.read_more')} <ChevronRight /></a>
          </div>
        )}
      </div>
      <div className="expert-card__foot">
        <a href="/#" className="button" onClick={handleExpertSelect}>{t('general.make_appointment')}</a>
      </div>
    </div>
  )
}

export default ExpertCard