import React, { useEffect, useState } from 'react'
import { Breadcrumb } from '../components'
import { t, currentLanguage } from '../utils'

const Experts = (props) => {

  const { refreshInteractions, history, partner } = props

  const [experts, setExperts] = useState([])

  useEffect(() => {
    refreshInteractions()
  }, [experts])

  useEffect(() => {
    if(!partner) {
      return
    }
    if(partner.experts) {
      setExperts(partner.experts)
    }
  }, [partner])

  const onNavigate = (path) => {
    history.push(path)
  }

  const renderBreadcrumbs = () => {
    let path = [{ label: t('account.dashboard'), url: '/dashboard' }, { label: t('dashboard.experts'), url: '/experts' }]
    return (
      <Breadcrumb path={path} navigate={onNavigate} />
    )
  }

  const renderExpert = (expert, ei) => {
    return (
      <div className="expert-content" key={`expert_${ei}`}>
        <div data-w-id="b2ee0f32-f9f1-6f75-54d0-3d9511aad7dc" className="help-title">
          <div className="expert">
            <img className="experts-circle-box" src={expert.image} alt="Expert profile photo" />
            <div className="experts-title">
              <h4 className="experts-h4">{ expert.name }</h4>
              <h5 className="experts-subtitle">{ expert.specialization[currentLanguage()] }</h5>
            </div>
          </div><img src="images/right-arrow.svg" alt="right orange arrow" width="10" height="16" className="faq-down-arrow"/></div>
        <div style={{ display: 'none', opacity: 0 }} className="help-text-wrap">
          <div className="help-text">{ expert.bio[currentLanguage()] } </div>
        </div>
      </div>
    )
  }

  return (
    <div className="content-wrap container container--with-breadcrumbs">
      { renderBreadcrumbs() }
      <div className="help-wrap">
        <h4 className="form__title">{ t('dashboard.experts') }</h4>
        { experts.map(renderExpert)}
      </div>
    </div>
  )

}

export default Experts
