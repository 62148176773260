import React from 'react'

import { t, currentLanguage } from '../../utils'

const VideoSection = ({ title = 'v1' }) => {
  return (
    <div className="video-section" id="video-section">
      <div className="video-section__left">
        <h3>{title === 'v1' ? t('landing.video_section_title') : t('landing.video_section_title_2')}</h3>
        <div className="text">
          <p>{t('landing.video_section_text').split('\n').map((text, i) => <span key={i}>{text}</span>)}</p>
        </div>
      </div>
      <div className="video-section__right">
        <iframe src={currentLanguage() === 'fr' ? "https://www.youtube.com/embed/GkbMuqtqnx0" : "https://www.youtube.com/embed/Lxmh47DwuFk"} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </div>
  )
}

export default VideoSection