import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import { currentLanguage, t } from '../utils'
import SolutionBlock from './SolutionBlock'
import config from '../config.json'
import { animateScroll as scroll } from 'react-scroll'

const APStepSolution = (props) => {

  const { startLoading, stopLoading, showSnackbar, onSolutionSelected, partner, onVoucherApplied, userData } = props

  const [solutions, setSolutions] = useState([])
  const [features, setFeatures] = useState([])
  useEffect(() => {
    if (!partner) {
      return
    }
    if (partner.solutions) {
      if(config.partner === 'axa' && !!userData.axaClientRequest === false){
        const copyOfSolutions = [...partner.solutions];
        const filteredSolutions = copyOfSolutions.filter(s => +s.price === 0);
        setSolutions(filteredSolutions)
      }else {
        const copyOfSolutions = [...partner.solutions];
        const filteredSolutions = copyOfSolutions.filter(s => +s.price !== 0);
        setSolutions(filteredSolutions)
      }
    }
    if (partner.solution_features) {
      setFeatures(partner.solution_features)
    }
  }, [partner])

  const renderSolution = (solution, si) => {
    return (
      <SolutionBlock key={`solution_${si}`} index={si} solution={solution} features={features} onSelect={selectSolution} startLoading={startLoading} stopLoading={stopLoading} showSnackbar={showSnackbar}
        onVoucherApplied={onVoucherApplied} />
    )
  }

  const selectSolution = (solutionIndex, solution) => () => {
    setTimeout(() => {
      scroll.scrollTo(250)
    }, 1);
    onSolutionSelected(solutionIndex, solution)
  }

  return (
    <div className="solution-wrapper">

      <div className="formules-wrap">
        <h3 className="h3 h3--bold h3--blue">{`${t('appointment.solutions_title')}`}<br /><br /></h3>
        <div className="row row--short2" style={{justifyContent: solutions.length === 1 ? 'center' : 'flex-start'}}>
          {solutions.map(renderSolution)}
        </div>
      </div>
    </div>
  )
}

export default APStepSolution