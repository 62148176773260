import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { KeyboardArrowRight } from '@material-ui/icons'
import { sortedArrayFromObject, updateUrlParams, paramsFromSearch, t, currentLanguage } from '../utils'
import config from '../config.json';

const SPStep1 = (props) => {

  const { onTopicSelect, history, refreshInteractions } = props

  const [topics, setTopics] = useState([])
  const [topicQuery, setTopicQuery] = useState('')

  useEffect(() => {
    refreshInteractions()
  }, [topics])

  useEffect(() => {
    // url query changed
    let params = paramsFromSearch(history.location.search)
    let tArray = sortedArrayFromObject(props.topics)
    let filteredTopics = []
    let tq = decodeURIComponent(params.topicQuery)
    setTopicQuery(tq && tq !== 'undefined' ? tq : '')
    if(!!tq && tq !== '' && tq !== 'undefined') {
      for(let t in tArray) {
        let topic = tArray[t]
        if(topic.hidden) {
          continue
        }
        if(topic.title[currentLanguage()].toLowerCase().includes(tq.toLowerCase())) {
          filteredTopics.push(topic)
        } else if(topic.description[currentLanguage()].toLowerCase().includes(tq.toLowerCase())) {
          filteredTopics.push(topic)
        } else if(topic.keywords && topic.keywords[currentLanguage()]) {
          for(let k in topic.keywords[currentLanguage()]) {
            let keyword = topic.keywords[currentLanguage()][k]
            const words = tq.trim().split(' ')
            if(words.length === 1 && keyword.toLowerCase().includes(words[0].toLowerCase())) {
              filteredTopics.push(topic)
              break
            }else if(words.length > 1) {
              for(let i = 0; i < words.length; i++) {
                if(keyword.toLowerCase().includes(words[i].toLowerCase())) {
                  if(i === words.length - 1 && filteredTopics.indexOf(topic) === -1) {
                    filteredTopics.push(topic)
                  }
                }else {
                  break
                }
              }
            }
          }
        }
      }
    } else {
      for(let i in tArray) {
        if(tArray[i].hidden) {
          continue
        }
        filteredTopics.push(tArray[i])
      }
    }
    setTopics([...filteredTopics])
    // refreshInteractions()
  }, [history.location, props.topics])

  const updateQuery = (t) => {
    updateUrlParams(history, { topicQuery: t }, false, true)
  }

  const navigate = (path) => () => {
    // setTimeout(() => {
    //   scroll.scrollTo(250)
    // }, 1);
    const { history } = props
    history.push(path)
  }

  return (
    <div className="container">
      {config.partner === 'axa' && <div className="container container--hero small">
        <h3 data-w-id="0e48b1ac-9288-3d3d-ae7b-164a2f0f8aa4" /* style="opacity:0" */ className="h3 h3--bold h3--blue"> {`${t('appointment.category_title')}` }<br/></h3>
        <div className="search-wrap step1">
          <div action="/search" className="search-box w-form">
            <input type="search" maxLength="256" name="query" placeholder={t('appointment.search_placeholder')} value={topicQuery} onChange={(e) => updateQuery(e.target.value)} required="" className="search-input w-input"/>
          </div>
        </div>
        <p data-w-id="133d0806-1598-9a63-c170-14392685c8c4" /* style="opacity:0" */ className="hero-subtitle">{ t('appointment.search_subtitle' )}<br/></p>
      </div>}

      {config.partner === 'lv' && <div className="container container--hero small container--make-appointment">
        <h3 data-w-id="0e48b1ac-9288-3d3d-ae7b-164a2f0f8aa4" /* style="opacity:0" */ className="h3 h3--bold h3--blue"> {t('appointment.category_title')}<br/></h3>
        <div className="button-wrapper">
          <div className="button" onClick={navigate('/appointment?topic=other&question=other')}>{t('appointment.to_appointment')} <KeyboardArrowRight /></div>
        </div>
        <p className="direct">{t('appointment.directly')}</p>
        <div className="or-wrapper">
          <div className="line"></div>
          <p>{t('general.or')}</p>
          <div className="line"></div>
        </div>
        <p>{t('appointment.search_title')}</p>
        <div className="search-wrap step1">
          <div action="/search" className="search-box w-form">
            <input type="search" maxLength="256" name="query" placeholder={t('appointment.search_placeholder')} value={topicQuery} onChange={(e) => updateQuery(e.target.value)} required="" className="search-input w-input"/>
          </div>
        </div>
      </div>}

      <div data-w-id="3494c3be-c299-6781-bdad-162aa3d99481" /* style="opacity:0" */ className="w-layout-grid grid grid--home">
        { topics.map((topic, ti) => {
          return (
            <div onClick={() => onTopicSelect(topic)} className="img-text-wrap" key={`topic_${ti}`}>
              <div className={`img-box img-box--${parseInt(ti) + 1}`} style={{ backgroundImage: `url('${topic.image}')`}}></div>
              <div className="overlay"></div>
              <div /* style="background-color:rgba(0,0,0,0)" */ className="text-bg"></div>
              <div /* style="-webkit-transform:translate3d(0, 175PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 175PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 175PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 175PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" */ className="text-box">
                <div className="grid__title-box">
                  <h3 className="h3 h3--white"><span>{ topic.title[currentLanguage()] }</span></h3>
                  <p className="grid__desc">{ topic.description[currentLanguage()] || '' }</p>
                </div>
                <div className="btn-wrap btn-wrap--short"><a data-w-id="bbd557fe-3b93-4b41-2157-50f6dd8551ec" href="#" className="btn btn--grid w-button"><span>{ t('home.help_question') }</span></a><img src="images/arrow-right__white.svg" alt="" className="img-arrow"/></div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SPStep1