import React, { useState, useEffect } from 'react'
import { updateUserData } from '../services/database'
import { safeValue, t, isEmailValid, isVatValid, isPhoneValid } from '../utils' 
import { signOut } from '../services/auth'
import config from '../config.json'

const ProfileTabInfo = (props) => {

  const { startLoading, stopLoading, showSnackbar, fetchUserData } = props

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [addressStreet, setAddressStreet] = useState('')
  const [addressNumber, setAddressNumber] = useState('')
  const [addressCity, setAddressCity] = useState('')
  const [addressZip, setAddressZip] = useState('')
  const [organisation, setOrganisation] = useState('')  
  const [vat, setVat] = useState('')
  const [cards, setCards] = useState([])

  useEffect(() => {
    setFirstName(safeValue(props.userData, 'name.first'))
    setLastName(safeValue(props.userData, 'name.last'))
    setEmail(safeValue(props.userData, 'contact.email'))
    setPhone(safeValue(props.userData, 'contact.phone'))
    setAddressStreet(safeValue(props.userData, 'address.street'))
    setAddressNumber(safeValue(props.userData, 'address.number'))
    setAddressCity(safeValue(props.userData, 'address.city'))
    setAddressZip(safeValue(props.userData, 'address.zip'))
    setVat(safeValue(props.userData, 'vat'))
    setOrganisation(safeValue(props.userData, 'organisation'))
    setCards(safeValue(props.userData, 'cards', 'array'))
  }, [props.userData])

  const doSignOut = async () => {
    startLoading('signingOut')
    await signOut()
    stopLoading('signingOut')
  }


  const saveChanges = async () => {
    if (!isDataComplete()) {
      showSnackbar({ text: t('account.data_incomplete'), color: 'error' })
      return
    }
    startLoading('updatingUser')
    let result = await updateUserData({
      name: {
        first: firstName,
        last: lastName
      },
      contact: {
        email: email,
        phone: phone
      },
      address: {
        street: addressStreet,
        number: addressNumber,
        city: addressCity,
        zip: addressZip
      },
      organisation: organisation,
      vat: vat,
      cards: cards
    })
    if(result.error) {
      showSnackbar({ text: result.error.message, color: 'error' })
    } else {
      showSnackbar({ text: t('profile.save_success'), color: 'success' })
      fetchUserData()
    }
    stopLoading('updatingUser')
  }

  const deleteCard = (cardIndex) => () => {
    let cs = cards
    cs.splice(cardIndex, 1)
    setCards([...cs])
  }

  const fieldLabel = (title, required) => {
    return `${title}${required ? ' *' : ''}`
  }

  const isDataComplete = () => {
    if (!phone || !isPhoneValid(phone)) {
      return false
    }
    else if (!email || !isEmailValid(email)) {
      return false
    }
    if (!lastName) {
      return false
    }
    if (!firstName) {
      return false
    }
    if(config.partner === 'axa') {
      if (!addressCity) {
          return false
        }
      if (!addressStreet) {
        return false
      }
      if (!addressNumber) {
        return false
      }
      if (!addressZip) {
        return false
      }
      if (!organisation) {
        return false
      }
      if (!vat || !isVatValid(vat)) {
        return false
      }
    }
    return true
  }

  const fieldRequired = () => {
    switch (config.partner) {
      case 'lv': 
        return false
      case 'axa':
        return true
      default: 
        return false
    }
  }
  
  return (
    <div className="form w-form">
      <div id="email-form" name="email-form" data-name="Email Form" className="form">
        <h4 className="form__title">{ t('nav.my_profile') }</h4>
        <div className="field-wrap">
          <div className={`form__field-wrap ${!lastName ? 'invalid' : ''}`}>
            <label htmlFor="Title-4" className="form__label">{fieldLabel(t('general.name_last'), true)}</label>
            <input value={lastName} onChange={e => setLastName(e.target.value)} type="text" className="form__text-field w-input" maxLength="256" name="Title-4" data-name="Title 4" id="Title-4" required=""/>
          </div>
          <div className={`form__field-wrap form__field-wrap--ml ${!firstName ? 'invalid' : ''}`}>
            <label htmlFor="Prenom" className="form__label">{fieldLabel(t('general.name_first'), true)}</label>
            <input value={firstName} onChange={e => setFirstName(e.target.value)} type="text" className="form__text-field w-input" maxLength="256" name="Prenom" data-name="Prenom" id="Prenom" required=""/>
            </div>
        </div>
        <div className="field-wrap">
          <div className={`form__field-wrap ${!isPhoneValid(phone) ? 'invalid' : ''}`}>
            <label htmlFor="N-de-t-l-phone" className="form__label">{ fieldLabel(t('profile.phone'), true) }</label>
            <div className="input-with-adornment-wrapper">
              <div className="form__text-field w-input input-adornment">{ '+32' }</div>
              <input value={phone} onChange={e => setPhone(e.target.value)} type="text" className="form__text-field w-input" maxLength="256" name="N-de-t-l-phone" data-name="N° de téléphone" id="N-de-t-l-phone" required=""/>
            </div>
          </div>
          <div className={`form__field-wrap form__field-wrap--ml ${!isEmailValid(email) ? 'invalid' : ''}`}>
            <label htmlFor="Email-4" className="form__label">{fieldLabel(t('general.email'), true)}</label>
            <input value={email} onChange={e => setEmail(e.target.value)} type="email" className="form__text-field w-input" maxLength="256" name="Email" data-name="Email" id="Email-4" required=""/>
            </div>
        </div>
        <h4 className="form__title form__title--mt">{ t('profile.organisation') }</h4>
        <div className="form__field-wrap width">
          <label htmlFor="Title-5" className="form__label">{fieldLabel(t('profile.organisation_name'), fieldRequired())}</label>
          <input value={organisation} onChange={e => setOrganisation(e.target.value)} type="text" className="form__text-field w-input" maxLength="256" name="Title-4" data-name="Title 4" id="Title-4" required=""/>
          </div>
        <div className="field-wrap">
          <div className="form__field-wrap">
            <label htmlFor="Adresse" className="form__label">{fieldLabel(t('profile.street'), fieldRequired())}</label>
            <input value={addressStreet} onChange={e => setAddressStreet(e.target.value)} type="text" className="form__text-field w-input" maxLength="256" name="Adresse" data-name="Adresse" id="Adresse" required=""/>
          </div>
          <div className="form__field-wrap form__field-wrap--sm">
            <label htmlFor="Prenom-2" className="form__label">{fieldLabel(t('profile.number'), fieldRequired())}</label>
            <input value={addressNumber} onChange={e => setAddressNumber(e.target.value)} type="text" className="form__text-field w-input" maxLength="256" name="Prenom-2" data-name="Prenom 2" id="Prenom-2" required=""/>
          </div>
        </div>
        <div className="field-wrap">
          <div className="form__field-wrap">
            <label htmlFor="Adresse-2" className="form__label">{fieldLabel(t('profile.city'), fieldRequired())}</label>
            <input value={addressCity} onChange={e => setAddressCity(e.target.value)} type="text" className="form__text-field w-input" maxLength="256" name="Adresse-2" data-name="Adresse 2" id="Adresse-2" required=""/>
          </div>
          <div className="form__field-wrap form__field-wrap--sm">
            <label htmlFor="Prenom-3" className="form__label">{fieldLabel(t('profile.zip'), fieldRequired())}</label>
            <input value={addressZip} onChange={e => setAddressZip(e.target.value)} type="text" className="form__text-field w-input" maxLength="256" name="Prenom-2" data-name="Prenom 2" id="Prenom-2" required=""/>
          </div>
        </div>
        <div className={`form__field-wrap width`}>
          <label htmlFor="N-de-TVA" className="form__label">{fieldLabel(t('profile.vat'), fieldRequired())}</label>
          <div className="input-with-adornment-wrapper">
            <div className="form__text-field w-input input-adornment">{ t('profile.vat_prefix') }</div>
            <input value={vat} onChange={e => setVat(e.target.value)} type="text" className="form__text-field w-input" maxLength="10" name="N-de-TVA" data-name="N° de TVA" id="N-de-TVA" required=""/>
          </div>
        </div>
        <h4 className="form__title form__title--mt">{ t('profile.payment_methods') }</h4>
        { cards.map((card, ci) => {
          return (
            <div className="form__row form__row--pt" key={`card_${ci}`}>
              <div className="form__img-t-wrap"><img src="images/visa.svg" alt="" className="form__img form__img--position" />
                <div className="form__text">{ `${card.brand} ---- ${card.last4}` }<br/>{ `Date d'expiration: ${card.exp_month}/${card.exp_year}` }</div>
              </div>
              <a href="#" onClick={deleteCard(ci)} className="delete-link w-inline-block"><img src="images/delete_forever-24px.svg" alt="" className="delete-img" /></a>
            </div>
          )
        })}
        <div className="form__btn-wrap">
          <input type="submit" value={t('general.save')} onClick={saveChanges} className="form__btn form__btn--green w-button"/>
          <input type="submit" value={t('account.sign_out')} onClick={doSignOut} className="form__btn w-button"/>
        </div>
      </div>
    </div>
    
  )
}

export default ProfileTabInfo
