import React from 'react'
import { t, currentLanguage } from '../utils'

const About = (props) => {

  const { partner } = props

  const title = () => {
    if(partner && partner.about) {
      return partner.about.title[currentLanguage()]
    } else {
      return ''
    }
  }

  const description = () => {
    if(partner && partner.about) {
      return partner.about.description[currentLanguage()]
    } else {
      return ''
    }
  }

  const logos = () => {
    if(partner && partner.about && partner.about.logos) {
      return partner.about.logos
    } else {
      return []
    }
  }

  return (
    <div className="container container--hero up">
      <div className="about-wrap">
        <h3 className="sec__h3 h3--bold">{ t('about.title') }<br/></h3>
        <h3 className="about__h3">{ title() }<br/></h3>
        <div className="about-logos-wrapper">
          { logos().map((logo, li) => {
            return (
              <img key={`about_logo_${li}`} src={logo.url} alt="" className="brand-logo" />
            )
          }) }
        </div>
        <p className="p p--left about-description">{ description() }</p>
      </div>
    </div>
  )
}

export default About