import firebase from './firebase'
import { currentLanguage } from '../utils'
import { functions } from 'firebase'
import { signOutBackend } from './functions'

const auth = firebase.auth()
auth.languageCode = currentLanguage()

const createUserStateChangedListener = (listener) => {
  return auth.onAuthStateChanged(listener)
}

const isUserSignedIn = () => {
  return !!auth.currentUser
}

const signInWithEmailAndPassword = async (email, password) => {
  try {
    let result = await auth.signInWithEmailAndPassword(email, password)
    return result
  } catch(err) {
    return { error: err }
  }
}

const createUserWithEmailAndPassword = async (email, password) => {
  try {
    let result = await auth.createUserWithEmailAndPassword(email, password)
    return result
  } catch(err) {
    return { error: err }
  }
}

const updateUserPassword = async (password) => {
  try {
    await auth.currentUser.updatePassword(password)
    return {}
  } catch(err) {
    return { error: err }
  }
}

const sendPasswordResetEmail = async (email) => {
  // let result = await functions.sendPasswordResetEmail(email, currentLanguage())
  try {
    await auth.sendPasswordResetEmail(email)
    return {}
  } catch(err) {
    return { error: err }
  }
}

const signOut = async () => {
  try {
    await signOutBackend()
    await auth.signOut()
    return {}
  } catch(err) {
    return { error: err }
  }
}

const userIdToken = async () => {
  if(!!auth.currentUser) {
    return await auth.currentUser.getIdToken()
  } else {
    return null
  }
}

const currentUser = () => {
  return auth.currentUser
}

const currentUserUid = () => {
  return auth.currentUser?.uid
}

export {
  isUserSignedIn,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  createUserStateChangedListener,
  signOut,
  updateUserPassword,
  userIdToken,
  currentUser,
  currentUserUid
}