import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'

const TopicSkeleton = (props) => {

  return (
    <div className="img-text-wrap" style={{height: 300, width: 300}}>
      <div className="skeleton-background-box"></div>
        {/* <div className="skeleton-overlay"></div> */}
        <div className="text-bg"></div>
        <div className="text-box">
          <div className="grid__title-box skeleton">
            {/* <h3 className="h3 h3--white">{topic.title[currentLanguage()]}<br /></h3>
            <p className="grid__desc">{topic.description[currentLanguage()]}</p> */}
            <LinearProgress className={`title-wrap-skeleton-line first col-${props.col}`} />
            <LinearProgress className={`title-wrap-skeleton-line col-${props.col}`} />
          </div>
          {/* <div className="btn-wrap btn-wrap--short"><a data-w-id="bbd557fe-3b93-4b41-2157-50f6dd8551ec" href="#" className="btn btn--grid w-button">{t('home.help_question')}</a><img src="images/arrow-right__white.svg" alt="" className="img-arrow" /></div> */}
      </div>
    </div>
    // <div className="col col--hero">
    //   <div className="hero-link w-inline-block">
    //     <div className="hero-cont">
    //       <div className="hero-img-wrap-skeleton"></div>
    //       <div className="title-wrap-skeleton">

            // <LinearProgress className={`title-wrap-skeleton-line first col-${props.col}`} />
            // <LinearProgress className={`title-wrap-skeleton-line col-${props.col}`} />
    //       </div>
    //       <div className="icon-wrap"><div className="hero-icon-skeleton" /></div>
    //     </div>
    //   </div>
    // </div>
  )

}

export default TopicSkeleton