import React, { useState, useEffect } from 'react'
import { animateScroll as scroll } from 'react-scroll'

import { t, sortedArrayFromObject, currentLanguage } from '../../utils'

const FAQ = ({ questions, history }) => {
  const [filteredQuestions, setFilteredQuestions] = useState([])

  useEffect(() => {
    if(Object.keys(questions).length > 0) {
      const qArr = sortedArrayFromObject(questions)
      const sectionQuestions = ['hNrtbPzEHOYuCrFFnc0I', 'uOLgXJT6nU7PIlj3TPb9', 'uUYiWgf7KEzaBXyEHml1', 'HUAkSAoIMcXHzRkxvAg2']
      let arr = []
      qArr.forEach(q => sectionQuestions.includes(q.id) && arr.push(q))
      setFilteredQuestions(arr)
    }
  }, [questions])

  const selectQuestion = (q) => {
    setTimeout(() => {
      scroll.scrollTo(250)
    }, 1);
    history.push({ pathname: `/appointment`, search: `location=appointment&topic=${q.topic}&question=${q.id}` })
  }

  return (
    <div className="faq">
      <div className="faq__head">
        <h3>{t('landing.faq_title')}</h3>
      </div>
      <div className="faq__body">
        <div className="faq__body_questions">
          {filteredQuestions.map((q, i) => (
            <div className="question" key={i} onClick={() => selectQuestion(q)}>
              <p>{q.text[currentLanguage()]}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="faq__actions">
        {/* <button className="button">{t('landing.faq_see_more')}</button> */}
        <button className="button" onClick={() => history.push({ pathname: '/appointment', search: 'location=skip&topic=other&question=other' })}>{t('landing.feature_btn_text')}</button>
      </div>
    </div>
  )
}

export default FAQ