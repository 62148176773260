import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie'

import * as introAnimation from '../../animations/intro.json'
import * as introAnimationNL from '../../animations/intro_nl.json'

import { t } from '../../utils'

const IntroTwo = ({ history, selectedLocale }) => {
  const [introAnimationOptions, setIntroAnimationOptions] = useState({})

  useEffect(() => {
    if(selectedLocale.includes('fr')) {
      setIntroAnimationOptions({
        loop: true,
        autoplay: true,
        animationData: introAnimation.default
      })
    }else {
      setIntroAnimationOptions({
        loop: true,
        autoplay: true,
        animationData: introAnimationNL.default
      })
    }
  }, [selectedLocale])

  return (
    <div className="intro-two">
      <div className="intro-two__inner">
        <div className="intro-two__inner_lottie">
          <Lottie options={introAnimationOptions} isStopped={false} isPaused={false} />
        </div>
        <h2>{t('landing.intro_title')}</h2>
        <div className="intro-two__textbox">
          <div className="intro-two__textbox_overlay"><div></div></div>
          <div className="intro-two__textbox_right">
            <div className="inner"><div></div></div>
          </div>
          <p>{t('landing.intro_two_text')}</p>
        </div>
        <button className="button" onClick={() => history.push({ pathname: '/appointment', search: 'location=skip&topic=other&question=other' })}>
          <span className="desktop">{t('home.to_appointment')} !</span>
          <span className="mobile">{t('navbar.appointment')}</span>  
        </button>
      </div>
    </div>
  )
}

export default IntroTwo