import React from 'react'

const Placeholder = (props) => {

  const { title } = props

  return (
    <div className="placeholder-wrapper">
      <h4 className="placeholder-title">{ title }</h4>
      <h4 className="placeholder-description">{ 'Feature will be added' }</h4>
    </div>
  )
}

export default Placeholder