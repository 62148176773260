import React from 'react'
import DoneIcon from '@material-ui/icons/DoneRounded'
import CloseIcon from '@material-ui/icons/CloseRounded'

import { t } from '../../utils'

const Packages = ({ history }) => {
  return (
    <div className="packages">
      <div className="packages__head">
        <h3>{t('home.solutions_title')}</h3>
      </div>
      <div className="packages__boxes">
        <div className="packages__box">
          <div className="packages__box_top">
            <p className="type">{t('landing.package_type_1')}</p>
          </div>
          <p className="price">49€ <span>{t('price.including_vat')}</span></p>
          <p className="offer">{t('landing.offer')}</p>
          <div className="packages__box_desc">
            <p>{t('landing.package_1_desc').split('\n').map((text, i) => <span key={i}>{text}</span>)}</p>
          </div>
          <ul>
            <li><DoneIcon /> {t('landing.feature_1')}</li>
            <li><DoneIcon /> {t('landing.feature_2')}</li>
            <li className="not-available"><CloseIcon /> {t('landing.feature_3')}</li>
          </ul>
        </div>
        <div className="packages__box">
          <div className="packages__box_top">
            <p className="type">{t('landing.package_type_2')}</p>
            <div className="popular">{t('appointment.best_choice')}</div>
          </div>
          <p className="price">69€ <span>{t('price.including_vat')}</span></p>
          <p className="offer">{t('landing.offer')}</p>
          <div className="packages__box_desc">
            <p>{t('landing.package_1_desc').split('\n').map((text, i) => <span key={i}>{text}</span>)}</p>
            <p>{t('landing.package_2_desc').split('\n').map((text, i) => <span key={i}>{text}</span>)}</p>
          </div>
          <ul>
            <li><DoneIcon /> {t('landing.feature_1')}</li>
            <li><DoneIcon /> {t('landing.feature_2')}</li>
            <li><DoneIcon /> {t('landing.feature_4')}</li>
          </ul>
        </div>
      </div>
      <div className="packages__foot">
        <button className="button button--white" onClick={() => history.push({ pathname: '/appointment', search: 'location=skip&topic=other&question=other' })}>{t('landing.packages_btn_text')}</button>
      </div>
    </div>
  )
}

export default Packages