import firebase from 'firebase/app'
import config from '../config.json'

require("firebase/auth")
require("firebase/firestore")
require("firebase/functions")
require("firebase/storage")


const axa_configuration = (environment) => { return {
  apiKey: "AIzaSyDUlOkHPbn7Pa_vuJXLHtm-dTsq5BTmoW8",
  authDomain: "axa-legal-expert.firebaseapp.com",
  databaseURL: "https://axa-legal-expert.firebaseio.com",
  projectId: "axa-legal-expert",
  storageBucket: `${environment}-axa-legal-expert`,
  messagingSenderId: "610954001341",
  appId: "1:610954001341:web:f42306beac5ca69bd3f0d4",
  measurementId: "G-E8QDKRMBR6"
}};

const lv_configuration = (environment) => { return {
  apiKey: "AIzaSyBIpkYfTixCdifwgBctnYdxHpUyPVJGCac",
  authDomain: "call-legal-expert.firebaseapp.com",
  databaseURL: "https://call-legal-expert.firebaseio.com",
  projectId: "call-legal-expert",
  storageBucket: `${environment}-call-legal-expert`,
  messagingSenderId: "454820213113",
  appId: "1:454820213113:web:fbde76a92a6454349effa8",
  measurementId: "G-8NEKEMBPXT"
}}

const configuration = () => {
  switch(config.partner) {
    case 'lv':
      return lv_configuration(config.environment)
    case 'axa':
      return axa_configuration(config.environment)
    default:
      return {}
  }
}

firebase.initializeApp(configuration())

export default firebase